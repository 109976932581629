<script type="text/babel">
import SubmissionDetailsOriginTile from "./contact/OriginTile";
import SubmissionSubjects from "@/components/submission/details/Subjects";
import SubmissionDetailsFormTile from "@/components/submission/details/contact/FormTile";
import SubmissionMixin from "./SubmissionMixin";

export default {
  name: "submission-meta-information",
  mixins: [SubmissionMixin],
  props: {
    store: {
      type: String,
      required: true
    }
  },
  components: {
    SubmissionDetailsFormTile,
    SubmissionSubjects,
    SubmissionDetailsOriginTile
  }
};
</script>

<template>
  <v-sheet color="blue lighten-5">
    <v-subheader class="submission-details__subheader">
      Meta
    </v-subheader>
    <v-list three-line dense class="blue lighten-5">
      <submission-details-origin-tile :submission="submission" />
      <submission-details-form-tile :form="submission.form" />
    </v-list>
    <submission-subjects
      :store="store"
      :submission="submission"
      @open-dialog="$emit('open-dialog', arguments[0])"
    />
  </v-sheet>
</template>
