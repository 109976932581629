<script type="text/babel">
import BAlert from "@/components/generic/BAlert";

export default {
  name: "submission-state-fallback",
  components: { BAlert },
  props: {
    store: {
      type: String,
      required: true
    }
  },
  data: () => ({
    processing: false
  }),
  computed: {
    hasDistributedOffers() {
      return this.$store.getters[`${this.store}/hasDistributedOffers`];
    },
    isFlaggingAsSpam() {
      return this.$store.getters[`${this.store}/isFlaggingAsSpam`];
    }
  }
};
</script>

<template>
  <b-alert
    type="info"
    icon="fa-fragile"
    header="FALLBACK SUBMISSION"
    message="Submissions collected through fallback forms are not able to collect all data."
    important
  >
    <template v-slot:actions>
      <v-btn
        class="error"
        small
        :loading="isFlaggingAsSpam"
        :disabled="isFlaggingAsSpam || hasDistributedOffers"
        @click="
          $store.commit(`${store}/openDialog`, {
            type: 'submission-dialog-mark-as-spam'
          })
        "
        >Flag as spam
      </v-btn>
    </template>
  </b-alert>
</template>
