<script type="text/babel">
import LeadHeader from "@/components/submission/lead/Header";
import Subjects from "@/components/submission/lead/Subjects";
import Offers from "@/components/submission/lead/Offers";
import SubmissionLeadOffersCreate from "./CreateOffer";
import LeadMixin from "@/components/submission/lead/LeadMixin";
import SubmissionLeadActions from "./Actions";
import SubmissionLeadPotentialOffers from "./PotentialOffers";
import SubmissionLeadFields from "@/components/submission/lead/Fields";

export default {
  name: "submission-lead-details",
  mixins: [LeadMixin],
  components: {
    SubmissionLeadFields,
    SubmissionLeadPotentialOffers,
    SubmissionLeadActions,
    SubmissionLeadOffersCreate,
    Offers,
    Subjects,
    LeadHeader
  },
  props: {
    last: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    showLeadFields: false,
    isOpen: true,
    isRemoving: false,
    isVisible: true,

    createOrPotential: false
  }),
  computed: {
    autoclaiming() {
      return this.$store.state.submission.autoclaimingLeads.includes(
        this.leadId
      );
    }
  },
  methods: {
    async removeLead() {
      this.isRemoving = true;
      await this.$store.dispatch("submission/removeLead", {
        leadId: this.lead.id
      });
      this.isRemoving = false;
    }
  }
};
</script>

<template>
  <v-card class="mb-3">
    <lead-header
      :lead="lead"
      :show-lead-fields.sync="showLeadFields"
      :open="isOpen"
      @toggle="isOpen = !isOpen"
      @open-dialog="$emit('open-dialog', arguments[0])"
    />
    <template v-if="isOpen">
      <subjects
        :lead="lead"
        @open-dialog="$emit('open-dialog', arguments[0])"
      />
      <submission-lead-fields
        v-if="!hasOffers || showLeadFields"
        :lead="lead"
        @open-dialog="$emit('open-dialog', arguments[0])"
      />
      <offers
        v-if="hasOffers"
        :lead="lead"
        :visible="isVisible"
        @open-dialog="$emit('open-dialog', arguments[0])"
      />
      <submission-lead-actions
        :lead="lead"
        :createOrPotential.sync="createOrPotential"
        :last="last"
        :autoclaiming="autoclaiming"
        @autoclaim="$emit('autoclaim', arguments[0])"
      />
      <v-container fluid pt-2 v-if="createOrPotential">
        <v-layout row>
          <v-flex xs12 px-0 class="primary--border">
            <submission-lead-offers-create
              v-model="createOrPotential"
              :lead="lead"
              :autoclaiming="autoclaiming"
              @open-dialog="$emit('open-dialog', arguments[0])"
              @autoclaim="$emit('autoclaim', arguments[0])"
            />
            <submission-lead-potential-offers
              v-model="createOrPotential"
              :lead="lead"
              :autoclaiming="autoclaiming"
              @open-dialog="$emit('open-dialog', arguments[0])"
            />
          </v-flex>
        </v-layout>
      </v-container>
    </template>
  </v-card>
</template>
