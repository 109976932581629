var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.alertClasses},[(_vm.shouldShowIconSlot)?_c('div',{staticClass:"b-alert__icon"},[_vm._t("icon",[_c('i',{class:[
          _vm.iconStyle,
          _vm.hasIcon ? _vm.icon : _vm.defaultIcon,
          'fa-fw',
          ("fa-" + _vm.iconSize + "x"),
          _vm.iconClass || 'b-alert__icon--default'
        ]})])],2):_vm._e(),_c('div',{staticClass:"b-alert__content"},[_vm._t("default",[(_vm.hasHeader)?[_c('div',{class:[
            'b-alert__header',
            _vm.headerClass || 'b-alert__header--default'
          ]},[_vm._v(" "+_vm._s(_vm.header)+" ")]),_c('div',{staticClass:"b-alert__message"},[_vm._v(_vm._s(_vm.message))])]:[_vm._v(" "+_vm._s(_vm.message)+" ")]])],2),(_vm.hasActionsSlot)?_c('div',{staticClass:"b-alert__actions"},[_vm._t("actions")],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }