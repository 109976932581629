<script type="text/babel">
import SubmissionLeadOfferMetaRecipientTile from "@/components/submission/lead/offer/RecipientTile";
import SubmissionLeadOfferMetaDistributeTile from "@/components/submission/lead/offer/DistributeTile";
import SubmissionLeadOfferMetaRejectionTile from "@/components/submission/lead/offer/RejectionTile";
import OfferMixin from "@/components/submission/lead/offer/OfferMixin";
import SubmissionLeadOfferMailPreview from "@/components/submission/lead/offer/SubmissionLeadOfferMailPreview";

export default {
  name: "submission-lead-offer-meta",
  mixins: [OfferMixin],
  components: {
    SubmissionLeadOfferMailPreview,
    SubmissionLeadOfferMetaRejectionTile,
    SubmissionLeadOfferMetaDistributeTile,
    SubmissionLeadOfferMetaRecipientTile
  },
  props: {
    processing: {
      type: Boolean,
      required: true
    },
    distributing: {
      type: Boolean,
      required: true
    },
    removing: {
      type: Boolean,
      required: true
    },
    rejecting: {
      type: Boolean,
      required: true
    },
    denying: {
      type: Boolean,
      required: true
    }
  }
};
</script>
<template>
  <v-container fluid pa-3 :class="contextColor">
    <v-layout row>
      <v-flex pa-0>
        <v-subheader
          class="submission-details__subheader pr-0"
          :dark="contextDark"
        >
          Offer {{ offer.id }}
          <span
            v-if="isDistributed"
            class="pl-3 primary--text text--lighten-2"
            >{{ offer.distributedAt | momentFromNow($i18n.locale) }}</span
          >
          <v-spacer />
          <submission-lead-offer-mail-preview :offer-id="offer.id" />
        </v-subheader>
      </v-flex>
    </v-layout>
    <v-layout row>
      <v-flex pa-0>
        <v-list three-line dense dark :class="contextColor">
          <submission-lead-offer-meta-recipient-tile
            :filter="offer.bulditFilter || offer.filter"
            :sub-filter="offer.bulditFilter === null ? undefined : offer.filter"
            :company="offer.company"
            :subject="offer.subject"
            :price="offer.price"
          />
          <div
            v-if="offer.deliveryError"
            class="tw-text-center tw-bg-error tw-py-3 tw-mx-3"
          >
            Previously tried to distribute, but an error occurred.
            <ul>
              <li v-for="(error, index) in offer.deliveryError" :key="index">
                {{ error }}
              </li>
            </ul>
          </div>
          <submission-lead-offer-meta-distribute-tile
            v-if="!isDistributed"
            :processing="processing"
            :distributing="distributing"
            :removing="removing"
            @distribute="$emit('distribute')"
            @remove="$emit('remove')"
          />
          <submission-lead-offer-meta-rejection-tile
            v-else-if="
              (isRejectionRequested && !isRejected) ||
                (!isRejected && !isRejectionRequested)
            "
            :offer="offer"
            :rejecting="rejecting"
            :denying="denying"
            @reject="$emit('reject', arguments[0])"
            @deny="$emit('deny-rejection', arguments[0])"
          />
        </v-list>
      </v-flex>
    </v-layout>
  </v-container>
</template>
