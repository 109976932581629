<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    scrollable
    lazy
    transition="dialog-bottom-transition"
    @keydown.esc="dialog = false"
  >
    <v-btn slot="activator" small icon>
      <v-icon small>far fa-search</v-icon>
    </v-btn>
    <v-card v-if="dialog" :key="renderKey">
      <v-toolbar dark color="primary">
        <v-toolbar-title>{{ `Previewing offer #${offerId}` }}</v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <v-btn icon dark @click.native="dialog = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-container fill-height fluid>
        <v-layout>
          <v-flex xs12>
            <iframe class="preview-frame" :src="url" />
          </v-flex>
        </v-layout>
      </v-container>
    </v-card>
  </v-dialog>
</template>
<script type="text/babel">
export default {
  name: "submission-lead-offer-mail-preview",
  props: {
    offerId: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    dialog: false,
    renderKey: undefined
  }),
  computed: {
    url() {
      return `${process.env.VUE_APP_API_ROOT}/offer/${this.offerId}/preview`;
    }
  },
  watch: {
    dialog: {
      handler(newValue) {
        if (newValue) {
          this.renderKey = Math.round(Math.random() * 10000000000000000);
        }
      }
    }
  }
};
</script>
<style scoped>
.preview-frame {
  width: 100%;
  height: 100%;
}
</style>
