<template>
  <v-dialog v-if="isAllowed" v-model="dialog" width="500">
    <v-btn slot="activator" icon dark>
      <v-icon class="far fa-file-download" />
    </v-btn>
    <v-card>
      <v-card-title class="headline primary white--text text-uppercase">
        Download selection
      </v-card-title>
      <v-card-text>
        <v-select
          v-if="isExportTypeShown"
          v-model="payload.exportType"
          :items="internalExportTypes"
          :disabled="isLoading"
          label="Type"
        />
        <v-select
          v-if="isDelimiterShown"
          v-model="payload.delimiter"
          :items="delimiterTypes"
          :disabled="isLoading"
          label="Delimiter"
        />
        <v-checkbox
          v-model="payload.isSinglePage"
          :disabled="isLoading"
          label="Only the current page"
        />
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-btn flat :disabled="isLoading" @click="dialog = false">
          Cancel
        </v-btn>
        <v-spacer />
        <v-btn
          :color="isError ? 'error' : 'primary'"
          :flat="!isError"
          :loading="isLoading"
          :disabled="isLoading"
          @click="confirm"
        >
          Download
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script type="text/babel">
import FileSaver from "file-saver";
import { createNamespacedHelpers } from "vuex";
import { AUTH_USER_LOCALE_CODE_JAVA } from "@/store/modules/locales/getter-types";

const { mapGetters: mapAuthGetters } = createNamespacedHelpers("auth");

const EXPORT_TYPE_LABELS = {
  csv: "Comma-separated value",
  xlsx: "Microsoft Excel"
};

const DATE_TIME_FORMAT_OPTIONS_DATETIME = {
  year: "2-digit",
  month: "2-digit",
  day: "2-digit",
  hour: "2-digit",
  minute: "2-digit",
  second: "2-digit"
};

const DATE_TIME_FORMAT_OPTIONS_DATE = {
  year: "2-digit",
  month: "2-digit",
  day: "2-digit"
};

const FILENAME_SANITIZE_REGEX = /[^a-z0-9]/gi;

export default {
  name: "export-toolbar-action",
  props: {
    action: {
      type: String,
      required: true
    },
    permission: {
      type: String,
      default: undefined
    },
    exportTypes: {
      type: Array,
      default: () => ["csv"],
      validator: value => value.length > 0
    },
    filename: {
      type: String,
      default: "export-%TIME%",
      validator: value => value.replace(FILENAME_SANITIZE_REGEX, "").length > 0
    }
  },
  data: () => ({
    dialog: false,
    isLoading: false,
    isError: false,
    delimiterTypes: [
      { text: ",", value: "," },
      { text: ";", value: ";" }
    ],
    payload: {
      exportType: undefined,
      isSinglePage: false,
      delimiter: ","
    }
  }),
  watch: {
    exportTypes: {
      handler(exportTypes) {
        // If the payload's exportType is undefined or if it's not included in the array
        // of exportTypes, set it to the first entry.
        if (
          this.payload.exportType === undefined ||
          !exportTypes.includes(this.payload.exportType)
        ) {
          this.payload.exportType = exportTypes[0];
        }
      },
      immediate: true
    },
    "payload.exportType": {
      handler() {
        // If the delimiter select should not be shown based on the payload export type,
        // there is no need for the delimiter property in the payload. Otherwise, and if
        // the payload's delimiter property is undefined, set it to the first
        // delimiterType defined.
        if (!this.isDelimiterShown) {
          this.payload.delimiter = undefined;
        } else if (this.payload.delimiter === undefined) {
          this.payload.delimiter = this.delimiterTypes[0].value;
        }
      }
    },
    immediate: true
  },
  computed: {
    ...mapAuthGetters(["hasRolesOrPermissions"]),
    /**
     * Returns true if the permissions props is either undefined or the authenticated
     * user has that permission or role.
     *
     * @returns {boolean}
     */
    isAllowed() {
      return (
        this.permission === undefined ||
        this.hasRolesOrPermissions(this.permission)
      );
    },
    /**
     * Returns true if the exportTypes props has more than one entry, false otherwise.
     *
     * @returns {boolean}
     */
    isExportTypeShown() {
      return this.exportTypes.length > 1;
    },
    /**
     * Returns true if the exportTypes props contains the 'csv' type, false otherwise.
     *
     * @returns {boolean}
     */
    isDelimiterShown() {
      return this.payload.exportType === "csv";
    },
    /**
     * Returns an array of simple {text, value} objects meant for the export types select.
     * If the type is a key in the EXPORT_TYPE_LABELS const, it's value will be used as
     * label for the select.
     *
     * @returns {{text: string, value: string}[]}
     */
    internalExportTypes() {
      return this.exportTypes.map(exportType => ({
        text: EXPORT_TYPE_LABELS[exportType] ?? `${exportType}`,
        value: `${exportType}`
      }));
    }
  },
  methods: {
    /**
     * Confirms the export by firing the action prop.
     *
     * @returns {Promise<void>}
     */
    async confirm() {
      this.isError = false;
      this.isLoading = true;
      try {
        const result = await this.$store.dispatch(this.action, this.payload);
        if (result === undefined) {
          // Undefined result indicates async generation, notify user.
          await this.$store.dispatch("snackbar/addInfo", {
            text:
              "The selection for the export was too large and is being generated in the background. You'll be notified when it's ready."
          });
        } else {
          // Otherwise have user download it.
          FileSaver.saveAs(
            new Blob([result], {
              type: `text/${this.payload.exportType}`
            }),
            this.getFilename()
          );
        }
        this.dialog = false;
      } catch (exception) {
        this.isError = true;
        console.error(exception);
      } finally {
        this.isLoading = false;
      }
    },
    /**
     * Returns a filename for the export based on the provided filename prop.
     * - Replaces & localises the following placeholders: %DATETIME%, %DATE%, %TIME%.
     * - Sanitizes input for usage as a filename.
     * This is a method instead of a computed property otherwise the Date object is cached.
     *
     * @returns {string}
     */
    getFilename() {
      // Use the authenticated user's locale for localization.
      const locale = this.$store.getters[
        `locales/${AUTH_USER_LOCALE_CODE_JAVA}`
      ];

      // Generate the placeholders replacements
      const date = new Date();
      const replacements = {
        "%DATETIME%": Intl.DateTimeFormat(
          locale,
          DATE_TIME_FORMAT_OPTIONS_DATETIME
        )
          .format(date)
          .replace(" ", "-"),
        "%DATE%": Intl.DateTimeFormat(locale, DATE_TIME_FORMAT_OPTIONS_DATE)
          .format(date)
          .replace(" ", "-"),
        "%TIME%": date.getTime()
      };

      // Replace the placeholders and sanitize string for usage as a fileName.
      const filename = this.filename
        .replace(/%\w+%/g, function(all) {
          return replacements[all] || all;
        })
        .replace(FILENAME_SANITIZE_REGEX, "-");

      // Add extension and return.
      return `${filename}.${this.payload.exportType}`;
    }
  }
};
</script>
