<script type="text/babel">
import { isNil } from "lodash";
import VAutocompleteRejectReason from "@/components/offer-rejection-reason/VAutocompleteOfferRejectionReason";
import OfferMixin from "@/components/submission/lead/offer/OfferMixin";
import CanValidate from "@/components/mixins/validation/CanValidate";
import IsValidationRoot from "@/components/mixins/validation/IsValidationRoot";

export default {
  name: "submission-lead-offer-meta-rejection-tile",
  mixins: [OfferMixin, CanValidate, IsValidationRoot],
  components: { VAutocompleteRejectReason },
  props: {
    accepting: {
      type: Boolean,
      default: false
    },
    denying: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    reason: null,
    comment: null,
    isRejecting: false
  }),
  watch: {
    "offer.rejectionReason": {
      handler() {
        if (!isNil(this.offer.rejectionReason)) {
          this.reason = this.offer.rejectionReason.id;
        }
      },
      immediate: true
    }
  },
  computed: {
    isRejectionShown() {
      return (
        this.isRejecting ||
        (this.isRejectionRequested && !this.isRejectionDenied)
      );
    },
    isRejectionOpen() {
      return (
        this.isRejectionRequested && !this.isRejected && !this.isRejectionDenied
      );
    }
  },
  methods: {
    reject() {
      this.$emit("reject", { reason: this.reason, comment: this.comment });
    },
    deny() {
      this.$emit("deny", { comment: this.comment });
    },
    cancel() {
      if (this.isRejectionOpen) {
        this.validate(this.deny);
      } else {
        this.isRejecting = false;
      }
    }
  }
};
</script>

<template>
  <div>
    <div class="px-3" v-if="isRejectionShown">
      <v-autocomplete-reject-reason v-model="reason" />
      <v-textarea
        v-model="comment"
        v-validate="'required|max:255'"
        data-vv-name="comment"
        :error-messages="errors.collect('comment')"
        :counter="255"
        outline
        label="Comment"
        auto-grow
      />
    </div>
    <v-list-tile>
      <v-list-tile-content>
        <v-container fluid>
          <v-layout row>
            <template v-if="isRejectionShown">
              <v-flex class="text-xs-center" xs6>
                <v-btn
                  flat
                  :disabled="accepting || denying"
                  :loading="denying"
                  @click="cancel"
                >
                  {{ isRejectionOpen ? "Deny" : "Cancel" }}
                </v-btn>
              </v-flex>
              <v-flex class="text-xs-center" xs6>
                <v-btn
                  flat
                  :disabled="denying || accepting"
                  :loading="accepting"
                  @click="validate(reject)"
                >
                  {{ isRejectionOpen ? "Accept" : "Reject" }}
                </v-btn>
              </v-flex>
            </template>
            <v-flex class="text-xs-center" xs12 v-else>
              <v-btn color="grey" flat @click="isRejecting = true">
                Reject
              </v-btn>
            </v-flex>
          </v-layout>
        </v-container>
      </v-list-tile-content>
    </v-list-tile>
  </div>
</template>
