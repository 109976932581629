<script type="text/babel">
import VChipSubject from "@/components/subject/VChipSubject";
import SubmissionMixin from "@/components/submission/details/SubmissionMixin";

export default {
  name: "submission-subjects",
  mixins: [SubmissionMixin],
  props: {
    store: {
      type: String,
      required: true
    }
  },
  components: { VChipSubject },
  methods: {
    edit() {
      if (this.canEditSubjects) {
        this.$emit("open-dialog", {
          type: "submission-dialog-subject-edit",
          props: { submission: this.submission, width: "900" }
        });
      }
    }
  }
};
</script>

<template>
  <v-container pt-0>
    <v-layout>
      <v-flex pl-1 pb-0>
        <legend class="v-list__tile__title">
          Subjects
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" icon small @click="edit">
                <v-icon small>far fa-edit fa-fw</v-icon>
              </v-btn>
            </template>
            <span v-if="canEditSubjects">Change subjects</span>
            <span v-else-if="isQueued"
              >This submission is queued for delivery to Solvari, so it can't be
              edited anymore</span
            >
            <span v-else-if="isDelivered"
              >This submission has been delivered to Solvari, so it can't be
              edited anymore</span
            >
            <span v-else-if="!isPreRefined"
              >This submission is still pre-refining, please wait before
              attempting to edit it.</span
            >
            <span v-else
              >This submission has distributed offers, so it can't be edited
              anymore.</span
            >
          </v-tooltip>
        </legend>
      </v-flex>
    </v-layout>
    <v-layout row wrap>
      <template v-if="submission.subjects.length > 0">
        <v-chip-subject
          v-for="subject in submission.subjects"
          :key="subject.id"
          :subject="subject"
          color="secondary"
          :class="
            addedSubjects.some(s => s.id === subject.id)
              ? 'accent--text'
              : undefined
          "
          dark
        />
        <v-chip-subject
          v-for="subject in removedSubjects"
          :key="subject.id"
          :subject="subject"
          disabled
        />
      </template>
      <template v-else>
        <v-flex xs12 class="text-xs-center">
          <v-sheet color="warning" class="ma-2 pa-2">
            <span
              ><i class="far fa-exclamation-triangle fa-fw" /> The submission
              does not have a subject, so at least one will need to be added
              before it can be separated into leads.</span
            >
          </v-sheet>
        </v-flex>
      </template>
    </v-layout>
  </v-container>
</template>
