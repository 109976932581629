<script type="text/babel">
import BAlert from "@/components/generic/BAlert";

export default {
  name: "submission-state-queued",
  components: { BAlert },
  data: () => ({})
};
</script>

<template>
  <b-alert type="info" icon important>
    <div class="headline text-uppercase info--text text--darken-2">
      This submission has been queued for delivery to Solvari.
    </div>
  </b-alert>
</template>
