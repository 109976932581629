<script type="text/babel">
import SubmissionFieldOptions from "@/components/submission/details/fields/OptionsField";
import SubmissionFieldText from "@/components/submission/details/fields/SubmissionTextField";
import SubmissionFieldMixin from "@/components/submission/details/fields/SubmissionFieldMixin";

export default {
  name: "submission-field",
  mixins: [SubmissionFieldMixin],
  components: { SubmissionFieldText, SubmissionFieldOptions },
  props: {
    hover: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<template>
  <v-layout row wrap>
    <v-flex xs12 py-1 class="pos-relative">
      <span
        :class="{
          'font-weight-bold': true,
          'grey--text': hasValue,
          'warning white--text px-2': !hasValue
        }"
      >
        {{ submissionField.label }}
      </span>
      <v-btn
        icon
        absolute
        right
        @click="
          $emit('open-dialog', {
            type: 'submission-field-edit-dialog',
            props: { submissionField }
          })
        "
        v-if="hover"
      >
        <v-icon>far fa-edit</v-icon>
      </v-btn>
    </v-flex>
    <v-flex xs12 py-1>
      <component
        :is="`submission-field-${hasOptions ? 'options' : 'text'}`"
        :submission-field="submissionField"
      />
    </v-flex>
    <v-flex xs12 py-1 v-if="!hasValue" class="warning--text">
      This field is required.
    </v-flex>
  </v-layout>
</template>
