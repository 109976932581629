<template>
  <persistent-if-changed-dialog
    v-model="internalDialog"
    v-bind="$attrs"
    footer-buttons
    v-on="$listeners"
    @cancel="internalDialog = false"
    :confirm-icon="confirmIcon"
    :confirm-text="confirmText"
    :cancel-icon="cancelIcon"
    :cancel-text="cancelText"
  >
    <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope"
      ><slot :name="slot" v-bind="scope"
    /></template>
  </persistent-if-changed-dialog>
</template>

<script>
import PersistentIfChangedDialog from "@/components/generic/dialog/PersistentIfChangedDialog";
export default {
  name: "submission-dialog",
  components: { PersistentIfChangedDialog },
  props: {
    dialogType: {
      type: String,
      required: true
    },
    confirmIcon: {
      type: String,
      default: null
    },
    confirmText: {
      type: String,
      default: "Save"
    },
    cancelIcon: {
      type: String,
      default: null
    },
    cancelText: {
      type: String,
      default: "Cancel"
    }
  },
  computed: {
    internalDialog: {
      set(value) {
        if (!value) {
          this.$store.commit(`submission/closeDialog`);
        }
      },
      get() {
        return this.$store.getters["submission/dialogType"] === this.dialogType;
      }
    }
  }
};
</script>
