<script type="text/babel">
import SubmissionLeadOfferField from "@/components/submission/lead/offer/fields/Field";
import FieldsBaseMixin from "../../FieldsBaseMixin";

export default {
  name: "submission-lead-offer-fields",
  components: {
    SubmissionLeadOfferField
  },
  mixins: [FieldsBaseMixin],
  props: {
    offer: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    showAll: true,
    onlySelected: true,
    highlightClass: "blue lighten-4"
  }),
  computed: {
    offerFields() {
      const fields = this.offer.fields
        .filter(field => field.group === "ALWAYS")
        .concat(this.offer.fields.filter(field => field.group === "SUBJECT"));
      if (this.showAll) {
        return fields.filter(field => field.name === "default_description");
      }
      return fields;
    }
  }
};
</script>

<template>
  <v-container fluid fill-height class="position--relative" pt-2>
    <v-btn icon absolute right v-if="false">
      <v-icon color="blue lighten-3" @click="showAll = !showAll"
        >far fa-{{ showAll ? "eye" : "eye-slash" }}
      </v-icon>
    </v-btn>
    <v-layout row wrap>
      <v-flex
        v-for="(offerField, index) in offerFields"
        :key="offerField.id"
        :xs12="showAll"
        :xs3="!showAll"
        @mouseover="highlight(offerField)"
        @mouseleave="highlight(offerField, false)"
        :class="getHighlightClass(offerField)"
      >
        <submission-lead-offer-field
          :offer-field="offerField"
          :hover="isHighlighted(offerField)"
          :show-all.sync="showAll"
          :showing-show-all="index === (showAll ? 0 : 3)"
          @open-dialog="$emit('open-dialog', arguments[0])"
        />
      </v-flex>
    </v-layout>
  </v-container>
</template>

<style scoped>
.position--relative {
  position: relative;
}
</style>
