<script type="text/babel">
import { isNil } from "lodash";

export default {
  name: "submission-details-default-tile",
  props: {
    name: {
      type: String,
      required: true
    },
    value: {
      type: String,
      required: true
    },
    original: {
      type: String,
      default: undefined
    },
    icon: {
      type: String,
      default: undefined
    },
    tileClass: {
      type: String,
      default: undefined
    }
  },
  computed: {
    isChanged() {
      return isNil(this.original) || this.value !== this.original;
    }
  }
};
</script>

<template>
  <v-list-tile :class="tileClass">
    <v-list-tile-action v-if="icon !== undefined">
      <v-icon>{{ icon }}</v-icon>
    </v-list-tile-action>
    <v-list-tile-content>
      <v-list-tile-title>{{ name }}</v-list-tile-title>
      <v-list-tile-sub-title class="submission-details__tile-subtitle"
        >{{ value }}
      </v-list-tile-sub-title>
      <v-list-tile-sub-title
        class="submission-details__tile-hint"
        v-if="isChanged"
        >{{ original }}
      </v-list-tile-sub-title>
    </v-list-tile-content>
  </v-list-tile>
</template>
