var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"fa-ul ml-4"},_vm._l((_vm.options),function(option){return _c('li',{key:option.id,class:{
      'grey--text text--lighten-1': !_vm.isSelected(option)
    }},[_c('span',{staticClass:"fa-li"},[_c('i',{class:{
          'fas fa-check-square': _vm.isSelected(option),
          'far fa-square': !_vm.isSelected(option),
          'accent--text':
            option.isSelected !==
              option.submissionFieldOption.isOriginallySelected &&
            !_vm.isInherited(option)
        }})]),_vm._v(" "+_vm._s(option.label)+" ")])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }