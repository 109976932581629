<script type="text/babel">
import LeadMixin from "@/components/submission/lead/LeadMixin";

export default {
  name: "submission-lead-header-sold-status",
  mixins: [LeadMixin],
  props: {
    color: {
      type: String,
      default: "primary"
    }
  }
};
</script>

<template>
  <div>
    <v-icon
      v-for="count in maxOfferCount"
      :key="count"
      :color="count > validOfferCount ? color : undefined"
      >{{ count > validOfferCount ? "far" : "fas" }} fa-user fa-fw count-{{
        count
      }}-{{ validOfferCount }}
    </v-icon>
  </div>
</template>
