<script type="text/babel">
import OfferFieldMixin from "./OfferFieldMixin";

export default {
  name: "submission-lead-offer-field-text",
  mixins: [OfferFieldMixin]
};
</script>

<template>
  <span class="body-1 text-pre-wrap" v-html="fieldValue" />
</template>
