<script type="text/babel">
import SubmissionStateRejected from "@/components/submission/details/state/Rejected";
import SubmissionStateSoftRejected from "@/components/submission/details/state/SoftRejected";
import SubmissionStateValidations from "@/components/submission/details/state/Validations";
import SubmissionStateFallback from "@/components/submission/details/state/Fallback";
import SubmissionStateUnprocessed from "@/components/submission/details/state/Unprocessed";
import SubmissionStateIncomplete from "@/components/submission/details/state/Incomplete";
import SubmissionStateQueued from "@/components/submission/details/state/Queued";
import SubmissionStateDelivered from "@/components/submission/details/state/Delivered";

export default {
  name: "submission-state",
  components: {
    SubmissionStateUnprocessed,
    SubmissionStateIncomplete,
    SubmissionStateFallback,
    SubmissionStateValidations,
    SubmissionStateSoftRejected,
    SubmissionStateRejected,
    SubmissionStateQueued,
    SubmissionStateDelivered
  },
  props: {
    store: {
      type: String,
      required: true
    }
  },
  computed: {
    state() {
      return this.$store.getters[`${this.store}/state`];
    },
    isProcessing() {
      return this.$store.getters[`${this.store}/isProcessing`];
    },
    isRejected() {
      return this.$store.getters[`${this.store}/isRejected`];
    },
    isSoftRejected() {
      return this.$store.getters[`${this.store}/isSoftRejected`];
    },
    isIncomplete() {
      return this.$store.getters[`${this.store}/isIncomplete`];
    },
    isQueued() {
      return this.$store.getters[`${this.store}/isQueued`];
    },
    isDelivered() {
      return this.$store.getters[`${this.store}/isDelivered`];
    },
    isPostProcessed() {
      return this.$store.getters[`${this.store}/isPostProcessed`];
    },
    isFallback() {
      return this.$store.getters[`${this.store}/isFallback`];
    },
    isWaitingForSubjects() {
      return this.$store.getters[`${this.store}/isWaitingForSubjects`];
    },
    isWaitingForFields() {
      return this.$store.getters[`${this.store}/isWaitingForFields`];
    },
    hasValidations() {
      return this.$store.getters[`${this.store}/hasValidations`];
    },
    components() {
      const components = [];
      // Fallback
      if (this.isFallback) {
        components.push("submission-state-fallback");
      }
      // Problems
      if (this.isProcessing) {
        components.push("submission-state-unprocessed");
      } else if (this.isRejected) {
        components.push("submission-state-rejected");
      } else if (this.isSoftRejected) {
        components.push("submission-state-soft-rejected");
      } else if (this.isIncomplete) {
        components.push("submission-state-incomplete");
      }
      // Validations
      if (this.hasValidations) {
        components.push("submission-state-validations");
      }

      return components;
    }
  }
};
</script>

<template>
  <div class="pb-2 px-2" v-if="components.length > 0">
    <div
      :class="{
        'pb-2': index !== components.length - 1
      }"
      v-for="(component, index) in components"
      :key="index"
    >
      <component
        :is="component"
        :store="store"
        @open-dialog="$emit('open-dialog', arguments[0])"
      />
    </div>
  </div>
</template>
