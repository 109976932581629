<script type="text/babel">
import { isNil } from "lodash";
import SubmissionLeadOfferMetaRecipientTileLimits from "@/components/submission/lead/offer/RecipientTileLimits";
import FilterMixin from "@/components/submission/lead/FilterMixin";

export default {
  name: "submission-lead-offer-meta-recipient-tile",
  mixins: [FilterMixin],
  components: { SubmissionLeadOfferMetaRecipientTileLimits },
  props: {
    filter: {
      type: Object,
      required: true
    },
    subFilter: {
      type: Object,
      default: undefined
    },
    company: {
      type: Object,
      default: undefined
    },
    subject: {
      type: Object,
      required: true
    },
    price: {
      type: Number,
      default: undefined
    },
    offerable: {
      type: Boolean,
      default: false
    },
    accent: {
      type: String,
      default: undefined
    },
    title: {
      type: String,
      default: undefined
    }
  },
  computed: {
    isTopActionSlotMounted() {
      return !isNil(this.$slots["top-action"]);
    },
    companies() {
      return this.isBulditFilter
        ? this.filter.filters.map(filterMapping => filterMapping.filter.company)
        : [this.company];
    },
    isPriceSet() {
      return !isNil(this.price);
    }
  },
  methods: {
    isSubFilterSelected(filter) {
      return !isNil(this.subFilter) && this.subFilter.id === filter.id;
    }
  }
};
</script>

<template>
  <v-list-tile :class="accent" :title="title">
    <v-list-tile-content>
      <v-list-tile-title>
        <router-link
          class="mr-1 text--cursor-pointer"
          :to="{ name: 'filters.show', params: { filterId: filter.id } }"
        >
          <v-icon class="fa-icon">far fa-filter</v-icon>
        </router-link>
        <v-chip small label v-if="isNonPriorityBulditFilter">BULDIT</v-chip>
        <v-chip small label v-else-if="isPriorityBulditFilter"
          >BULDIT PRIORITY
        </v-chip>
        <v-chip small label v-else-if="isResellerFilter">RESELLER</v-chip>
        <span class="offer-meta__filter pr-3">{{
          filter.name || filter.id
        }}</span>
        <submission-lead-offer-meta-recipient-tile-limits
          :current-weekly="filter.currentWeeklyLeads"
          :max-weekly="filter.maxWeeklyLeads"
          v-if="isTopActionSlotMounted"
        />
      </v-list-tile-title>
      <v-list-tile-sub-title>
        <template v-if="isBulditFilter">
          <template v-for="(filterMapping, index) in filter.filters">
            <router-link
              :key="`${index}-link`"
              class="mr-1 text--cursor-pointer"
              :to="{
                name: 'company.show',
                params: { companyId: filterMapping.filter.company.id }
              }"
            >
              <v-icon class="fa-icon">far fa-building</v-icon>
            </router-link>
            <span
              :key="`${index}-sub-filter`"
              class="mr-3"
              :class="{ success: isSubFilterSelected(filterMapping.filter) }"
            >
              <span class="offer-meta__sub-filter__company"
                >{{ filterMapping.filter.company.name }}
              </span>
              <span class="offer-meta__company_percentage">
                {{ filterMapping.percentage }}%
              </span>
            </span>
          </template>
        </template>
        <template v-else>
          <router-link
            class="mr-1 text--cursor-pointer"
            :to="{ name: 'company.show', params: { companyId: company.id } }"
          >
            <v-icon class="fa-icon">far fa-building</v-icon>
          </router-link>
          <span class="offer-meta__company">{{ company.name }}</span>
        </template>
      </v-list-tile-sub-title>
      <v-list-tile-sub-title>
        <router-link
          class="mr-1 text--cursor-pointer"
          :to="{ name: 'subjects.show', params: { subjectId: subject.id } }"
        >
          <v-icon class="fa-icon">far fa-dot-circle</v-icon>
        </router-link>
        <span class="offer-meta__subject">{{ subject.name }}</span>
      </v-list-tile-sub-title>
    </v-list-tile-content>
    <v-list-tile-action>
      <slot name="top-action">
        <submission-lead-offer-meta-recipient-tile-limits
          :current-weekly="filter.currentWeeklyLeads"
          :max-weekly="filter.maxWeeklyLeads"
          v-if="!isTopActionSlotMounted"
        />
      </slot>
      <v-list-tile-action-text class="headline">
        <span v-if="isPriceSet">
          {{ $store.getters["locales/getLocalizedCurrency"](price) }}
        </span>
        <span v-else>€ ?</span>
      </v-list-tile-action-text>
    </v-list-tile-action>
  </v-list-tile>
</template>

<style scoped>
.text--cursor-pointer {
  cursor: pointer;
}
.fa-icon {
  font-size: 13px !important;
}

.selected {
  background: red;
}

.accent-gradient.lighten-5 {
  background-image: linear-gradient(
    45deg,
    #ffffaf 25%,
    #ffffff 25%,
    #ffffff 50%,
    #ffffaf 50%,
    #ffffaf 75%,
    #ffffff 75%,
    #ffffff 100%
  );
  background-size: 56.57px 56.57px;
  background-repeat: initial;
}
</style>
