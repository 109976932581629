<script type="text/babel">
import BAlert from "@/components/generic/BAlert";
import { isNil } from "lodash";

export default {
  name: "submission-state-rejected",
  components: { BAlert },
  props: {
    store: {
      type: String,
      required: true
    }
  },
  computed: {
    rejectReason() {
      return this.$store.getters[`${this.store}/rejectReasonNormalized`];
    },
    hasRejectComment() {
      return this.$store.getters[`${this.store}/hasRejectComment`];
    },
    rejectComment() {
      return this.$store.getters[`${this.store}/rejectComment`];
    },
    rejectReasonName() {
      return isNil(this.rejectReason) ? "" : this.rejectReason.name;
    }
  }
};
</script>

<template>
  <b-alert type="error" icon="fa-vote-nay" important>
    <div class="headline">
      <span class="error--text text--darken-2">REJECTED:</span>
      {{ rejectReasonName }}
    </div>
    <div v-if="hasRejectComment" class="text-pre-line">
      {{ rejectComment }}
    </div>
  </b-alert>
</template>
