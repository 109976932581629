<script type="text/babel">
import SubmissionDialog from "@/components/submission/dialog/SubmissionDialog";
import DialogMixin from "@/components/submission/dialog/DialogMixin";

export default {
  name: "submission-dialog-revalidate",
  components: { SubmissionDialog },
  mixins: [DialogMixin],
  methods: {
    async save() {
      await this.$store.dispatch("submission/revalidate");
    }
  }
};
</script>

<template>
  <submission-dialog
    dialog-type="submission-dialog-revalidate"
    header="revalidate submission"
    color="warning"
    confirmText="revalidate"
    :changed="true"
    :loading="loading"
    @confirm="confirm"
  >
    <p class="body-2">
      This forces the submission to go through validation again. Are you sure
      you know what you're doing?
    </p>
  </submission-dialog>
</template>
