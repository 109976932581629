<template>
  <v-container fluid pt-0>
    <v-layout v-for="offer in offers" :key="offer.id" row>
      <v-flex xs12 px-0 pb-0>
        <offer
          :lead="lead"
          :offer="offer"
          :processing="$store.getters['submission/isOfferProcessing'](offer.id)"
          :distributing="distributingOffers.includes(offer.id)"
          :removing="removingOffers.includes(offer.id)"
          :rejecting="rejectingOffers.includes(offer.id)"
          :denying="rejectionDenyingOffers.includes(offer.id)"
          @distribute="distribute(offer.id)"
          @remove="remove(offer.id)"
          @reject="reject(offer.id, arguments[0])"
          @deny-rejection="denyRejection(offer.id, arguments[0])"
          @open-dialog="$emit('open-dialog', arguments[0])"
        />
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script type="text/babel">
import Offer from "@/components/submission/lead/offer/Details";

export default {
  name: "submission-lead-offers",
  components: { Offer },
  props: {
    lead: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    removingOffers: [],
    distributingOffers: [],
    rejectingOffers: [],
    rejectionDenyingOffers: [],
    create: false
  }),
  computed: {
    offers() {
      return this.$store.getters["submission/leadOffers"](this.lead.id);
    }
  },
  methods: {
    async remove(offerId) {
      this.removingOffers.push(offerId);
      await this.$store.dispatch("submission/removeOffer", {
        leadId: this.lead.id,
        offerId
      });
      this.removingOffers.splice(this.removingOffers.indexOf(offerId), 1);
    },
    async distribute(offerId) {
      this.distributingOffers.push(offerId);

      await this.$store.dispatch("submission/distributeOffer", {
        leadId: this.lead.id,
        offerId
      });

      this.distributingOffers.splice(
        this.distributingOffers.indexOf(offerId),
        1
      );
    },
    async reject(offerId, { reason, comment }) {
      this.rejectingOffers.push(offerId);
      await this.$store.dispatch("submission/rejectOffer", {
        offerId,
        reason,
        comment
      });
      this.rejectingOffers.splice(this.rejectingOffers.indexOf(offerId), 1);
    },
    async denyRejection(offerId, { comment }) {
      this.rejectionDenyingOffers.push(offerId);
      await this.$store.dispatch("submission/denyOfferRejection", {
        offerId,
        comment
      });
      this.rejectionDenyingOffers.splice(
        this.rejectionDenyingOffers.indexOf(offerId),
        1
      );
    }
  }
};
</script>
