<template>
  <v-dialog
    v-model="dialog"
    :persistent="changed"
    lazy
    :max-width="width"
    @keydown.esc="$emit('cancel')"
  >
    <v-btn
      slot="activator"
      small.
      :icon="isIconActivator"
      :class="activatorClass"
      v-if="activator"
    >
      <v-icon small v-if="isIconActivator">
        {{ activatorIcon }}
      </v-icon>
      {{ activatorText }}
    </v-btn>
    <v-card>
      <v-toolbar :color="color" dark>
        <v-toolbar-title>
          <span class="headline text-uppercase">
            {{ header }}
          </span>
        </v-toolbar-title>
        <v-spacer />
        <slot name="header-buttons">
          <template v-if="headerButtons">
            <v-btn
              v-if="cancelable"
              :icon="!cancelText"
              :class="cancelClass"
              :disabled="loading || !changed || disabledButtons"
              @click="$emit('cancel')"
            >
              <v-icon>{{ cancelIcon }}</v-icon>
              {{ cancelText }}
            </v-btn>
            <v-btn
              :icon="!confirmText"
              :class="confirmClass"
              :disabled="loading || !changed || disabledButtons"
              :loading="loading"
              @click="$emit('confirm')"
            >
              <v-icon>{{ confirmIcon }}</v-icon>
              {{ confirmText }}
            </v-btn>
          </template>
        </slot>
      </v-toolbar>
      <v-card-text class="pa-3">
        <slot />
      </v-card-text>
      <slot name="footer">
        <v-card-actions v-if="footerButtons">
          <v-btn
            v-if="cancelable"
            flat
            :icon="!cancelText"
            :class="cancelClass"
            :disabled="loading || !changed || disabledButtons"
            @click="$emit('cancel')"
          >
            <v-icon v-if="cancelIcon">{{ cancelIcon }}</v-icon>
            {{ cancelText }}
          </v-btn>
          <v-spacer></v-spacer>
          <slot name="prepend-confirm" />
          <v-btn
            flat
            :icon="!confirmText"
            :class="confirmClass"
            :disabled="loading || !changed || disabledButtons"
            :loading="loading"
            @click="$emit('confirm')"
          >
            <v-icon v-if="confirmIcon">{{ confirmIcon }}</v-icon>
            {{ confirmText }}
          </v-btn>
          <slot name="append-confirm" />
        </v-card-actions>
      </slot>
    </v-card>
    <span
      class="persistent-when-changed-absolute-slot"
      v-if="!!$slots['absolute-button']"
    >
      <slot name="absolute-button" />
    </span>
  </v-dialog>
</template>
<script type="text/babel">
import { isNil } from "lodash";

export default {
  name: "persistent-if-changed-dialog",
  props: {
    value: {
      type: Boolean,
      default: false
    },
    header: {
      type: String,
      default: undefined
    },
    color: {
      type: String,
      default: "primary"
    },
    changed: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    disabledButtons: {
      type: Boolean,
      default: false
    },
    cancelable: {
      type: Boolean,
      default: true
    },
    activator: {
      type: Boolean,
      default: false
    },
    activatorIcon: {
      type: String,
      default: undefined
    },
    activatorText: {
      type: String,
      default: undefined
    },
    activatorClass: {
      type: String,
      default: undefined
    },
    confirmIcon: {
      type: String,
      default: "far fa-fw fa-save"
    },
    confirmText: {
      type: String,
      default: undefined
    },
    confirmClass: {
      type: String,
      default: undefined
    },
    cancelIcon: {
      type: String,
      default: "far fa-fw fa-undo"
    },
    cancelText: {
      type: String,
      default: undefined
    },
    cancelClass: {
      type: String,
      default: undefined
    },
    footerButtons: {
      type: Boolean,
      default: false
    },
    headerButtons: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: "500px"
    }
  },
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    },
    isIconActivator() {
      return this.activator && !isNil(this.activatorIcon);
    }
  }
};
</script>
<style lang="scss">
// keeps the absolute buttons in the bottom right from scrolling
// don't make this scoped, the css needs access to the child elements
.persistent-when-changed-absolute-slot {
  position: sticky;
  bottom: 0;
  left: 100%;
  width: 0;
  height: 0;
  & > *,
  & > *:hover {
    position: absolute !important;
    bottom: 10px !important;
    right: 10px !important;
  }
}
</style>
