<template>
  <v-container fluid py-0>
    <v-layout row wrap>
      <v-flex xs12 py-0 pl-1>
        <v-text-field
          v-if="canGeocode"
          v-model="geocodeString"
          label="Geocoder Override"
          :loading="$store.state.submission.isGeocoding"
          :disabled="!canGeocode"
          :append-outer-icon="geocodeFieldIcon"
          @click:append-outer="fetchGeocode"
        />
        <div v-else-if="isQueued" class="text-xs-center subheading pt-3">
          This submission has been queued for delivery to Solvari.
        </div>
        <div v-else-if="isDelivered" class="text-xs-center subheading pt-3">
          This submission has been delivered to Solvari.
        </div>
        <div v-else class="text-xs-center subheading pt-3">
          This submission is still pre-refining, please wait before attempting
          to geocode it.
        </div>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script type="text/babel">
import SubmissionMixin from "@/components/submission/details/SubmissionMixin";

export default {
  name: "submission-geocoder",
  mixins: [SubmissionMixin],
  props: {
    store: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      geocodeString: null,
      items: [],
      isLoading: false,
      geocodeResult: null
    };
  },
  watch: {
    canGeocode(value) {
      if (value) {
        this.geocodeString = this.geocodeQuery;
        this.fetchGeocode();
      }
    }
  },
  created() {
    this.geocodeString = this.geocodeQuery;
    this.fetchGeocode();
  },
  computed: {
    geocodeFieldIcon() {
      if (!this.canGeocode) {
        return undefined;
      }
      return this.$store.state[this.store].isGeocoding
        ? "far fa-sync fa-spin"
        : "far fa-sync";
    }
  },
  methods: {
    fetchGeocode() {
      // If the submission can be geocoded, fetch some geocode results.
      if (this.canGeocode) {
        this.$emit("fetch-geocode", { q: this.geocodeString });
      }
    }
  }
};
</script>
