<template>
  <v-menu v-if="actions.length > 0" bottom left class="pl-2">
    <v-btn slot="activator" dark icon>
      <v-icon>more_vert</v-icon>
    </v-btn>
    <v-list light>
      <v-list-tile
        v-for="(action, i) in actions"
        :key="i"
        :disabled="action.disabled"
        @click.stop="
          $emit('open-dialog', {
            type: action.dialog
          })
        "
      >
        <v-list-tile-title>{{ action.text }}</v-list-tile-title>
      </v-list-tile>
    </v-list>
  </v-menu>
</template>
<script type="text/babel">
import SubmissionMixin from "@/components/submission/details/SubmissionMixin";

export default {
  name: "submission-header-actions",
  mixins: [SubmissionMixin],
  props: {
    store: {
      type: String,
      required: true
    }
  },
  computed: {
    canProcessValidations() {
      return this.$store.getters[`${this.store}/canProcessValidations`];
    },
    actions() {
      return [
        this.rejectAction,
        this.resetAddressAction,
        this.revalidateAction
      ];
    },
    rejectAction() {
      return {
        text: "Reject",
        disabled:
          this.isRejected ||
          this.isSoftRejected ||
          this.isQueued ||
          this.isDelivered ||
          this.isPostProcessed,
        dialog: "submission-dialog-reject"
      };
    },
    resetAddressAction() {
      return {
        text: "Reset address",
        disabled: !this.canEditLocation,
        dialog: "submission-dialog-address-reset"
      };
    },
    revalidateAction() {
      return {
        text: "Revalidate",
        disabled: !this.canProcessValidations,
        dialog: "submission-dialog-revalidate"
      };
    }
  }
};
</script>
