<script type="text/babel">
import SubmissionLeadHeaderSoldStatus from "@/components/submission/lead/header/SoldStatus";
import LeadMixin from "@/components/submission/lead/LeadMixin";
import SubmissionLeadHeaderActions from "./HeaderActions";

export default {
  name: "submission-lead-header",
  mixins: [LeadMixin],
  components: { SubmissionLeadHeaderActions, SubmissionLeadHeaderSoldStatus },
  props: {
    open: {
      type: Boolean,
      default: true
    },
    showLeadFields: {
      type: Boolean,
      required: true
    }
  }
};
</script>

<template>
  <v-toolbar :color="contextColor" dark dense>
    <v-toolbar-side-icon @click="$emit('toggle')">
      <v-icon
        >far fa-{{ open ? "clipboard-list-check" : "chevron-down" }} fa-fw
      </v-icon>
    </v-toolbar-side-icon>
    <v-toolbar-title>
      <span class="text-uppercase pr-2" :class="contextSecondaryClass"
        >Lead</span
      >
      <span>{{ lead.id }}</span>
    </v-toolbar-title>
    <v-spacer></v-spacer>
    <submission-lead-header-sold-status
      :lead="lead"
      :color="contextSecondaryColor"
    />
    <v-divider vertical inset class="pl-3" />
    <submission-lead-header-actions
      :lead="lead"
      :show-lead-fields="showLeadFields"
      @update:show-lead-fields="$emit('update:show-lead-fields', arguments[0])"
      @open-dialog="$emit('open-dialog', arguments[0])"
    />
  </v-toolbar>
</template>
