<script type="text/babel">
import { cloneDeep, isNil } from "lodash";
import { isChanged } from "@/store/change";
import IsValidationRoot from "@/components/mixins/validation/IsValidationRoot";
import CanValidate from "@/components/mixins/validation/CanValidate";

export default {
  name: "submission-dialog-mixin",
  mixins: [CanValidate, IsValidationRoot],
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    // FORM
    form: {},
    // CONFIRM ACTION
    confirmAction: undefined,
    // DIALOG CONFIG PROPERTIES
    title: undefined,
    color: undefined,
    footer: undefined,
    confirmIcon: undefined,
    confirmText: undefined,
    cancelIcon: undefined,
    cancelText: undefined
  }),
  created() {
    this.$emit("update:loading", false);
    this.form = cloneDeep(this.unchangedForm);
    this.config();
  },
  watch: {
    isChanged: {
      handler(value) {
        this.$emit("change", value);
      },
      immediate: true
    }
  },
  computed: {
    isLoading: {
      get() {
        return this.loading;
      },
      set(value) {
        this.$emit("update:loading", value);
      }
    },
    unchangedForm() {
      return {};
    },
    isChanged() {
      return isChanged(this.form, this.unchangedForm);
    }
  },
  methods: {
    config({
      title,
      color,
      footer,
      confirmIcon,
      confirmText,
      cancelIcon,
      cancelText
    } = {}) {
      this.$emit("config", {
        title: isNil(title) ? this.title : title,
        color: isNil(color) ? this.color : color,
        footer: isNil(footer) ? this.footer : footer,
        confirmIcon: isNil(confirmIcon) ? this.confirmIcon : confirmIcon,
        confirmText: isNil(confirmText) ? this.confirmText : confirmText,
        cancelIcon: isNil(cancelIcon) ? this.cancelIcon : cancelIcon,
        cancelText: isNil(cancelText) ? this.cancelText : cancelText
      });
    },
    confirm() {
      this.validate(this.save);
    },
    save() {
      this.$emit("update:loading", true);
      this.$emit("confirm", {
        type: this.confirmAction,
        payload: this.form
      });
    }
  }
};
</script>
