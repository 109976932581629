<script type="text/babel">
import BAlert from "@/components/generic/BAlert";

export default {
  name: "submission-state-incomplete",
  props: {
    store: {
      type: String,
      required: true
    }
  },
  components: { BAlert },
  data: () => ({
    mappings: {
      subjects: {
        header: "no subjects",
        message:
          "This submission does not have a subject. As refinement rules and validation rules both rely on subjects, at least one subject will need to be added in order for the submission to continue processing."
      },
      geocode: {
        header: "has not been geocoded",
        message:
          "This submission has not been geocoded. As refinement rules and validation rules both rely location, one will have to be provided in order for the submission to continue processing."
      },
      fields: {
        header: "missing required field values",
        message:
          "One or more required fields are missing values. Buldit filters often take these into account, so all required fields will need to be filled in order to continue processing."
      }
    }
  }),
  computed: {
    mappingType() {
      if (this.$store.getters[`${this.store}/isWaitingForSubjects`]) {
        return "subjects";
      }
      if (this.$store.getters[`${this.store}/isWaitingForGeocode`]) {
        return "geocode";
      }
      if (this.$store.getters[`${this.store}/isWaitingForFields`]) {
        return "fields";
      }
      return undefined;
    }
  }
};
</script>

<template>
  <b-alert
    type="warning"
    :header="mappings[mappingType].header"
    :message="mappings[mappingType].message"
    important
    icon
  />
</template>
