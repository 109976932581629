<script type="text/babel">
import { isNil } from "lodash";

export default {
  name: "submission-mixin",
  props: {
    submission: {
      type: Object,
      required: true
    }
  },
  computed: {
    // ALLOWED EDITING STATE
    canGeocode() {
      // Geocoding is allowed when a submission is pre-refined, but not geocoded yet.
      return this.isPreRefined && !this.isGeocoded;
    },
    canEditPersonalDetails() {
      // Editing personal details is allowed when a submission is pre-refined, but before any offers are distributed.
      return this.isPreRefined && !this.hasDistributedOffers;
    },
    canEditLocation() {
      // Editing location details is allowed when a submission is geocoded, but before any offers are distributed.
      return this.isGeocoded && !this.hasDistributedOffers;
    },
    canEditSubjects() {
      // Editing subjects is allowed when a submission is pre-refined, but before it is separated.
      return this.isPreRefined && !this.isSeparated;
    },
    canEditFields() {
      // Editing the submission fields is allowed when a submission is pre-refined and geocoded, but before any offers
      // are distributed.
      return this.isPreRefined && this.isGeocoded && !this.hasDistributedOffers;
    },
    // STATE
    isPreRefined() {
      return !isNil(this.submission.preRefinedAt);
    },
    isPostRefined() {
      return !isNil(this.submission.postRefinedAt);
    },
    haSubjects() {
      return this.submission.subjects.length > 0;
    },
    isGeocoded() {
      return this.submission.isGeocoded;
    },
    isRejected() {
      return this.submission.isRejected;
    },
    isSoftRejected() {
      return this.submission.isSoftRejected;
    },
    isValidated() {
      return this.submission.isValidated;
    },
    isQueued() {
      return this.$store.getters["submission/isQueued"];
    },
    isDelivered() {
      return this.$store.getters["submission/isDelivered"];
    },
    isPostProcessed() {
      return this.$store.getters["submission/isPostProcessed"];
    },
    isProcessed() {
      // @TODO Nah, how about we check whether all leads are autoclaimed?
      return (
        this.isPreRefined &&
        this.submission.isGeocoded &&
        this.submission.isRefined &&
        this.submission.isValidated &&
        this.isPostRefined &&
        this.submission.isSeparated
      );
    },
    state() {
      if (this.isRejected) {
        return "rejected";
      }
      if (this.isSoftRejected) {
        return "soft-rejected";
      }
      if (this.isProcessed || this.isPostProcessed) {
        return "processed";
      }
      if (this.submission.isSeparated) {
        return "separated";
      }
      if (this.isPostRefined) {
        return "post-refined";
      }
      if (this.submission.isValidated) {
        return "validated";
      }
      if (this.submission.isRefined) {
        return "refined";
      }
      if (this.submission.isGeocoded && this.isPreRefined) {
        return "geocoded";
      }
      if (this.isPreRefined) {
        return "pre-refined";
      }
      if (this.isQueued) {
        return "queued";
      }
      if (this.isDelivered) {
        return "delivered";
      }
      return "none";
    },
    /**
     * A submission is processing whenever it's state is not processed, with TWO notable exceptions:
     * - when it's pre-refined but not geocoded, as it's waiting for user input
     * - when it's validated but invalid, as it's waiting for user input
     *
     * @returns {boolean}
     */
    isProcessing() {
      // If state is 'rejected', return false.
      if (this.isRejected) {
        return false;
      }

      // If state is 'soft-rejected', return false.
      if (this.isSoftRejected) {
        return false;
      }

      // If state is 'queued', return false.
      if (this.isQueued) {
        return false;
      }

      // If state is 'pre-refined', but the submission's address is not geocoded yet, return false as we're waiting for
      // user input.
      if (this.isWaitingForGeocode) {
        return false;
      }

      // submission is waiting for subject, return false as it's waiting for user input.
      if (this.isWaitingForSubjects) {
        return false;
      }

      // If the submission has been validated but the submission is not valid, return false as we're waiting for user
      // input.
      if (this.isWaitingForValidation) {
        return false;
      }

      // If the submission has been post-refined, but all fields have not been filled yet, return false as we're
      // waiting for user input.
      if (this.isWaitingForFields) {
        return false;
      }

      // Otherwise return true if the state is not 'processed'.
      return this.state !== "processed";
    },

    hasProblem() {
      return ["rejected", "soft-rejected"].includes(this.state);
    },
    addedSubjects() {
      return this.submission.subjects.filter(
        subject =>
          !this.submission.originalSubjects
            .map(originalSubject => originalSubject.id)
            .includes(subject.id)
      );
    },
    removedSubjects() {
      return this.submission.originalSubjects.filter(
        originalSubject =>
          !this.submission.subjects
            .map(subject => subject.id)
            .includes(originalSubject.id)
      );
    },
    contextColor() {
      switch (this.state) {
        case "rejected":
          return "error";
        case "processed":
          return "success";
        default:
          return "warning";
      }
    },
    buddyIcon() {
      if (this.hasBuddy) {
        return "far fa-user fa-fw";
      }
      return "";
    },
    contextDark() {
      switch (this.contextColor) {
        case "error":
        case "success":
        case "warning":
          return true;
        default:
          return false;
      }
    },
    hasAddress() {
      return !isNil(this.submission.address);
    },
    hasCountry() {
      return !isNil(this.submission.address.country);
    },
    hasProvince() {
      return !isNil(this.submission.address.province);
    },
    hasCity() {
      return !isNil(this.submission.address.city);
    },
    hasForm() {
      return !isNil(this.submission.form);
    },
    hasWebsite() {
      return !isNil(this.submission.website);
    },
    hasArticle() {
      return !isNil(this.submission.article);
    },
    // BUDDY
    hasBuddy() {
      return !isNil(this.submission.buddy);
    },
    hasBuddyProblems() {
      return this.hasBuddy;
    },
    buddyContextClass() {
      return this.hasBuddyProblems ? "orange lighten-4" : "blue lighten-5";
    },
    fullName() {
      return `${this.submission.firstName} ${this.submission.lastName}`;
    },
    originalFullName() {
      return `${this.submission.originalFirstName} ${this.submission.originalLastName}`;
    },
    offers() {
      return this.submission.leads.reduce(
        (accumulator, lead) => accumulator.concat(lead.offers || []),
        []
      );
    },
    unrejectedOffers() {
      return this.offers.filter(offer => !isNil(offer.rejectedAt));
    },
    hasOffers() {
      return this.offers.length !== 0;
    },
    hasUnrejectedOffers() {
      return this.unrejectedOffers.length !== 0;
    },
    hasDistributedOffers() {
      return this.offers.some(offer => !isNil(offer.distributedAt));
    },
    hasUnrejectedDistributedOffers() {
      return this.unrejectedOffers.some(offer => !isNil(offer.distributedAt));
    },
    /**
     * Returns the address as a string for geocoding.
     *
     * @returns string
     */
    geocodeQuery() {
      let query = this.street;
      query = this.addToLine(query, this.streetNumber);
      query = this.addToLine(query, this.box);
      query = this.addToLine(query, this.postalCode, ", ");
      return this.addToLine(
        query,
        this.locality,
        isNil(this.postalCode) ? ", " : " "
      );
    },
    /**
     * Returns the submission's definite street.
     *
     * @returns string
     */
    street() {
      return this.hasAddress
        ? this.submission.address.street
        : this.submission.originalData.street;
    },
    /**
     * Returns the submission's definite street number.
     *
     * @returns {*}
     */
    streetNumber() {
      return this.hasAddress
        ? this.submission.address.streetNumber
        : this.submission.originalData.streetNumber;
    },
    /**
     * Returns the submission's definite box.
     *
     * @returns {*}
     */
    box() {
      return this.hasAddress
        ? this.submission.address.box
        : this.submission.originalData.box;
    },
    /**
     * Returns the submission's definite postal code.
     *
     * @returns string
     */
    postalCode() {
      return this.hasAddress
        ? this.submission.address.postalCode
        : this.submission.originalData.postalCode;
    },
    /**
     * Returns the submission's definite locality (city name).
     *
     * @returns string
     */
    locality() {
      if (!this.hasAddress) {
        return this.submission.originalData.city;
      }
      return this.hasCity
        ? this.submission.address.city.name
        : this.submission.address.locality;
    },
    /**
     * Returns the province's name or 'NO PROVINCE' if null.
     *
     * @returns string
     */
    provinceName() {
      if (this.hasAddress && this.hasProvince) {
        return this.submission.address.province.name;
      }
      return "NO PROVINCE";
    },
    /**
     * Returns the country name or 'NO COUNTRY' if null.
     *
     * @returns string
     */
    countryName() {
      if (this.hasAddress && this.hasCountry) {
        return this.submission.address.country.name;
      }
      return "NO COUNTRY";
    },
    /**
     * Returns the submission's address line 1. (Street street number box)
     *
     * @returns string
     */
    addressLine1() {
      let line1 = this.submission.address.street;
      line1 = this.addToLine(line1, this.submission.address.streetNumber);
      return this.addToLine(line1, this.submission.address.box);
    },
    /**
     * Returns the submission's original address line 1. (Street street number box from original data)
     *
     * @returns string
     */
    addressLine1Original() {
      let line1 = this.submission.originalData.street;
      line1 = this.addToLine(line1, this.submission.originalData.streetNumber);
      return this.addToLine(line1, this.submission.originalData.box);
    },
    /**
     * Returns the submission's address line 2. (postal code city)
     *
     * @returns string
     */
    addressLine2() {
      let line2 = this.submission.address.postalCode;
      return this.addToLine(
        line2,
        this.hasCity
          ? this.submission.address.city.name
          : this.submission.address.locality
      );
    },
    /**
     * Returns the submission's original address line 2. (postal code city from original data)
     *
     * @returns string
     */
    addressLine2Original() {
      let line2 = this.submission.originalData.postalCode;
      return this.addToLine(line2, this.submission.originalData.city);
    },
    /**
     * Returns true if address line 1 differs from original address line 1.
     *
     * @returns boolean
     */
    isAddressLine1Changed() {
      return this.addressLine1Original !== this.addressLine1;
    },
    /**
     * Returns true if address line 2 differs from original address line 2.
     *
     * @returns boolean
     */
    isAddressLine2Changed() {
      return this.addressLine2Original !== this.addressLine2;
    },
    telephoneNumbers() {
      const telephoneRefinements = this.submission.refinements.filter(
        refinement => refinement.target === "TELEPHONE"
      );
      if (telephoneRefinements.length > 0) {
        return telephoneRefinements[telephoneRefinements.length - 1].after;
      }
      return this.submission.telephoneNumbers;
    }
  },
  methods: {
    addToLine(line, string, separator = " ") {
      if (isNil(string) || string.trim() === "") {
        return line;
      }
      return `${line}${separator}${string}`;
    }
  }
};
</script>
