import { render, staticRenderFns } from "./RecipientTile.vue?vue&type=template&id=17044818&scoped=true&"
import script from "./RecipientTile.vue?vue&type=script&lang=js&"
export * from "./RecipientTile.vue?vue&type=script&lang=js&"
import style0 from "./RecipientTile.vue?vue&type=style&index=0&id=17044818&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "17044818",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListTile } from 'vuetify/lib/components/VList';
import { VListTileAction } from 'vuetify/lib/components/VList';
import { VListTileActionText } from 'vuetify/lib/components/VList';
import { VListTileContent } from 'vuetify/lib/components/VList';
import { VListTileSubTitle } from 'vuetify/lib/components/VList';
import { VListTileTitle } from 'vuetify/lib/components/VList';
installComponents(component, {VChip,VIcon,VListTile,VListTileAction,VListTileActionText,VListTileContent,VListTileSubTitle,VListTileTitle})
