<script type="text/babel">
import { isNil } from "lodash";

export default {
  name: "submission-lead-offer-meta-recipient-tile-limits",
  props: {
    currentWeekly: {
      type: Number,
      required: true
    },
    maxWeekly: {
      type: Number,
      default: undefined
    }
  },
  computed: {
    isUnlimited() {
      return isNil(this.maxWeekly);
    },
    isSaturated() {
      return !this.isUnlimited && this.currentWeekly >= this.maxWeekly;
    }
  }
};
</script>

<template>
  <span
    class="recipient-tile-limit text-uppercase px-2"
    :class="{ warning: isSaturated }"
    >Weekly&nbsp;<span class="recipient-tile-limit__current">{{
      currentWeekly
    }}</span
    >&nbsp;/&nbsp;<span class="recipient-tile-limit__max" v-if="isUnlimited"
      ><i class="far fa-infinity"/></span
    ><span class="recipient-tile-limit__max" v-else>{{ maxWeekly }}</span></span
  >
</template>
