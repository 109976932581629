<script type="text/babel">
import LeadFieldMixin from "@/components/submission/lead/fields/LeadFieldMixin";

export default {
  name: "submission-lead-field-options",
  mixins: [LeadFieldMixin],
  props: {
    onlySelected: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    options() {
      return this.onlySelected ? this.selectedOptions : this.leadField.options;
    }
  }
};
</script>

<template>
  <ul class="fa-ul ml-4">
    <li
      v-for="option in options"
      :key="option.id"
      :class="{
        'grey--text text--lighten-1': !isSelected(option)
      }"
    >
      <span class="fa-li">
        <i
          :class="{
            'fas fa-check-square': isSelected(option),
            'far fa-square': !isSelected(option),
            'accent--text':
              option.isSelected !==
                option.submissionFieldOption.isOriginallySelected &&
              !isInherited(option)
          }"
        />
      </span>
      {{ option.label }}
    </li>
  </ul>
</template>
