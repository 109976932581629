<script type="text/babel">
import SubmissionStateValidation from "@/components/submission/details/state/Validation";

export default {
  name: "submission-state-validations",
  props: {
    store: {
      type: String,
      required: true
    }
  },
  components: { SubmissionStateValidation },
  computed: {
    validations() {
      return this.$store.getters[`${this.store}/validations`];
    },
    validationRules() {
      return this.$store.getters[`${this.store}/validationRules`];
    },
    isRejected() {
      return this.$store.getters[`${this.store}/isRejected`];
    },
    isSoftRejected() {
      return this.$store.getters[`${this.store}/isSoftRejected`];
    },
    hasDistributedOffers() {
      return this.$store.getters[`${this.store}/hasDistributedOffers`];
    }
  }
};
</script>

<template>
  <div>
    <div
      :class="{
        'mb-2': i !== validations.length - 1
      }"
      v-for="(validation, i) in validations"
      :key="validation.id"
    >
      <submission-state-validation :store="store" :validation="validation" />
    </div>
  </div>
</template>
