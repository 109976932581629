<script type="text/babel">
import { isNil } from "lodash";

export default {
  name: "submission-geocode-list-item",
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    hasStreet() {
      return !isNil(this.item.street);
    },
    hasStreetNumber() {
      return !isNil(this.item.streetNumber);
    },
    hasPostalCode() {
      return !isNil(this.item.postalCode);
    },
    hasLocality() {
      return !isNil(this.item.locality);
    },
    isPartialMatch() {
      return !this.hasStreet;
    },
    line1() {
      let line1 = isNil(this.item.street) ? "NO STREET" : this.item.street;
      // Add street number if there is one.
      line1 = this.addToLine(line1, this.item.streetNumber);
      // Add box if there is one.
      line1 = this.addToLine(line1, this.item.box);
      // Add postal code if there is one
      line1 = this.addToLine(line1, this.item.postalCode, ", ");
      // Add locality if there is one
      return this.addToLine(
        line1,
        this.item.locality,
        isNil(this.item.postalCode) ? ", " : undefined
      );
    },
    line2() {
      let line2 = this.item.countryName;
      line2 = this.addToLine(line2, this.item.administrativeArea1, " > ");
      return this.addToLine(line2, this.item.administrativeArea2, " > ");
    }
  },
  methods: {
    addToLine(line, string, separator = " ") {
      if (isNil(string) || string.trim() === "") {
        return line;
      }
      return `${line}${separator}${string}`;
    }
  }
};
</script>

<template>
  <v-list-tile :avatar="isPartialMatch">
    <v-list-tile-avatar v-if="isPartialMatch">
      <v-icon class="error" color="white">far fa-map-marker-slash fa-fw</v-icon>
    </v-list-tile-avatar>
    <v-list-tile-content>
      <v-list-tile-title>
        <span v-if="hasStreet && hasStreetNumber"
          >{{ item.street }}&nbsp;{{ item.streetNumber }}</span
        >
        <template v-else-if="hasStreet">
          <span>{{ item.street }}</span
          >&nbsp;<span class="error white--text px-1">NO NR</span>
        </template>
        <span v-else class="error white--text px-1">NO STREET</span>
        ,&nbsp;
        <template v-if="hasPostalCode">{{ item.postalCode }}</template>
        <span class="error white--text px-1" v-else>NO POSTAL CODE</span>
        &nbsp;
        <template v-if="hasLocality">{{ item.locality }}</template>
        <span class="error white--text px-1" v-else>NO LOCALITY</span>
      </v-list-tile-title>
      <v-list-tile-sub-title>
        <span>{{ line2 }}</span>
      </v-list-tile-sub-title>
    </v-list-tile-content>
    <v-list-tile-action>
      <v-btn
        icon
        :disabled="$store.state.submission.isGeocoding"
        @click="$emit('geocode')"
      >
        <v-icon>far fa-check fa-fw</v-icon>
      </v-btn>
    </v-list-tile-action>
  </v-list-tile>
</template>
