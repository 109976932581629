<template>
  <tr>
    <td class="pr-0">
      {{ submission.id }}
      <v-icon class="fallback-icon pl-1 pr-0 mr-0" v-if="submission.isFallback"
        >fas fa-fragile
      </v-icon>
    </td>
    <td>{{ submission.fullName }}</td>
    <td>{{ hasWebsite ? submission.website.host : submission.origin }}</td>
    <td>
      <v-chip v-for="subject in submission.subjects" :key="subject.id" label>
        {{ subject.name }}
      </v-chip>
    </td>
    <td>
      <offer-state-chip
        v-for="offer in offers"
        :key="offer.id"
        :offer="offer"
      />
    </td>
    <td>
      {{ submission.completedAtCorrect | momentFromNow($i18n.locale) }}
    </td>
    <td>
      <submission-state-chip :state="submission.state" />
    </td>
    <td>
      <v-btn
        icon
        :to="{
          name: 'submissions.show',
          params: { submissionId: submission.id }
        }"
      >
        <v-icon>far fa-search</v-icon>
      </v-btn>
    </td>
  </tr>
</template>
<script type="text/babel">
import { DateTime } from "luxon";
import SubmissionMixin from "@/components/submission/details/SubmissionMixin";
import SubmissionStateChip from "@/components/submission/SubmissionStatusChip";
import OfferStateChip from "@/components/offer/OfferStateChip";

export default {
  name: "submissions-table-row",
  mixins: [SubmissionMixin],
  components: { OfferStateChip, SubmissionStateChip },
  computed: {
    rowClass() {
      return {
        warning:
          !this.submission.isRejected &&
          (this.submission.isFlagged ||
            !this.submission.isGeocoded ||
            !this.submission.isRefined ||
            !this.submission.isValidated ||
            !this.submission.isSeparated),
        error: this.submission.isRejected
      };
    }
  },
  methods: {
    localizeDateTime(timestamp) {
      return new DateTime.fromISO(timestamp).toFormat("yyyy-LL-dd HH:mm:ss");
    }
  }
};
</script>

<style scoped>
.fallback-icon {
  font-size: 1.25rem;
}
</style>
