<script type="text/babel">
import { isNil } from "lodash";
import SubmissionField from "@/components/submission/details/fields/Field";
import FieldsBaseMixin from "../FieldsBaseMixin";

export default {
  name: "submission-fields",
  mixins: [FieldsBaseMixin],
  props: {
    fields: {
      type: Array,
      required: true
    }
  },
  components: {
    SubmissionField
  },
  computed: {
    descriptionField() {
      return this.fields.find(field => field.name === "default_description");
    },
    isDescriptionLong() {
      return (
        !isNil(this.descriptionField.value) &&
        this.descriptionField.value.length > 450
      );
    },
    displayableFields() {
      const alwaysFields = this.fields.filter(
        field => field.group === "ALWAYS"
      );
      const subjectFields = this.fields.filter(
        field => field.group === "SUBJECT"
      );
      return alwaysFields.concat(subjectFields);
    },
    displayableFieldsWithoutDescription() {
      return this.displayableFields.filter(
        field => field.id !== this.descriptionField.id
      );
    }
  }
};
</script>

<template>
  <v-container fluid fill-height pt-2>
    <v-layout row wrap>
      <template v-if="isDescriptionLong">
        <v-flex
          xs3
          @mouseover="highlight(descriptionField)"
          @mouseleave="highlight(descriptionField, false)"
          :class="{ 'grey lighten-4': isHighlighted(descriptionField) }"
        >
          <submission-field
            :submission-field="descriptionField"
            :hover="isHighlighted(descriptionField)"
            @open-dialog="$emit('open-dialog', arguments[0])"
          />
        </v-flex>
        <v-flex xs9>
          <v-layout row wrap>
            <v-flex
              xs4
              v-for="submissionField in displayableFieldsWithoutDescription"
              :key="submissionField.id"
              @mouseover="highlight(submissionField)"
              @mouseleave="highlight(submissionField, false)"
              :class="{ 'grey lighten-4': isHighlighted(submissionField) }"
              xs3
            >
              <submission-field
                :submission-field="submissionField"
                :hover="isHighlighted(submissionField)"
                @open-dialog="$emit('open-dialog', arguments[0])"
              />
            </v-flex>
          </v-layout>
        </v-flex>
      </template>
      <template v-else>
        <v-flex
          v-for="submissionField in displayableFields"
          :key="submissionField.id"
          @mouseover="highlight(submissionField)"
          @mouseleave="highlight(submissionField, false)"
          :class="getHighlightClass(submissionField)"
          xs3
        >
          <submission-field
            :submission-field="submissionField"
            :hover="isHighlighted(submissionField)"
            @open-dialog="$emit('open-dialog', arguments[0])"
          />
        </v-flex>
      </template>
    </v-layout>
  </v-container>
</template>
