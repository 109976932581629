<script type="text/babel">
import { sumBy } from "lodash";
import SubmissionLeadOfferMetaRecipientTile from "./RecipientTile";
import PotentialOfferTileCreateBtn from "@/components/submission/lead/offer/PotentialOfferTileCreateButton";

export default {
  name: "potential-offer-group-tile",
  components: {
    PotentialOfferTileCreateBtn,
    SubmissionLeadOfferMetaRecipientTile
  },
  props: {
    group: {
      type: Array,
      required: true
    },
    offers: {
      type: Array,
      required: true
    },
    creatingOffers: {
      type: Array,
      required: true
    },
    maxOffers: {
      type: Number,
      required: true
    }
  },
  computed: {
    totalPrice() {
      const sum = sumBy(this.group, "price");
      return this.$store.getters["locales/getLocalizedCurrency"](sum);
    },
    isCreatingGroup() {
      return this.group.every(
        offer =>
          this.creatingOffers.map(o => o.filter).includes(offer.filter.id) &&
          this.creatingOffers.map(o => o.subject).includes(offer.subject.id)
      );
    },
    canAddGroup() {
      const isOneOrMoreOfferAlreadyAdded = this.group.some(offer =>
        this.isOfferAlreadyAdded(offer)
      );
      return (
        !isOneOrMoreOfferAlreadyAdded &&
        (this.isCreatingGroup ||
          this.group.length > this.maxOffers - this.offers.length)
      );
    }
  },
  methods: {
    isOfferAlreadyAdded(offer) {
      return this.offers.some(
        o =>
          o.subject.id === offer.subject.id && o.filter.id === offer.filter.id
      );
    }
  }
};
</script>

<template>
  <v-list-tile>
    <v-container fluid py-0 pl-0 pr-3>
      <v-layout row>
        <v-flex v-for="(offer, index) in group" :key="index" xs4 pa-0>
          <submission-lead-offer-meta-recipient-tile
            :filter="offer.filter"
            :company="offer.filter.company"
            :subject="offer.subject"
            :price="offer.price"
            :accent="
              isOfferAlreadyAdded(offer) ? 'accent lighten-5' : undefined
            "
            :title="offer.remark"
          >
            <template v-slot:top-action>
              <potential-offer-tile-create-btn
                :potential-offer="offer"
                :offers="offers"
                :creating-offers="creatingOffers"
                @create="$emit('create', arguments[0])"
              />
            </template>
          </submission-lead-offer-meta-recipient-tile>
        </v-flex>
      </v-layout>
    </v-container>
    <v-list-tile-action>
      <v-list-tile-action-text>
        <v-btn
          :disabled="canAddGroup"
          :loading="isCreatingGroup"
          small
          @click="$emit('create-group', group)"
        >
          ADD GROUP
        </v-btn>
      </v-list-tile-action-text>
      <v-list-tile-action-text class="headline"
        >{{ totalPrice }}
      </v-list-tile-action-text>
    </v-list-tile-action>
  </v-list-tile>
</template>
