<script type="text/babel">
import SubmissionMixin from "../SubmissionMixin";

export default {
  name: "submission-details-origin-tile",
  mixins: [SubmissionMixin]
};
</script>

<template>
  <v-list-tile>
    <v-list-tile-content>
      <v-list-tile-title>
        <span>Origin</span>
        <span class="float-right pr-3">{{
          submission.completedAtCorrect | momentTimestamp($i18n.locale)
        }}</span>
        <span class="float-right grey--text text--lighten-1 pr-3">{{
          submission.completedAtCorrect | momentFromNow($i18n.locale)
        }}</span>
      </v-list-tile-title>
      <v-list-tile-sub-title class="submission-details__tile-subtitle">
        {{ submission.origin }}
      </v-list-tile-sub-title>
      <v-list-tile-sub-title>
        <router-link
          v-if="hasWebsite"
          class="pr-2"
          hint="Website"
          :to="{
            name: 'websites.show',
            params: { websiteId: submission.website.id }
          }"
        >
          <i class="far fa-fw fa-globe" />
        </router-link>
        <span v-else class="error--text text-uppercase pr-2">
          <i class="far fa-fw fa-globe" />
          No website
        </span>
        <router-link
          v-if="hasArticle"
          class="pr-2"
          :to="{
            name: 'articles.show',
            params: { articleId: submission.article.id }
          }"
        >
          <i class="fas fa-fw fa-globe" />
        </router-link>
        <span v-else class="error--text text-uppercase pr-2">
          <i class="fas fa-fw fa-globe" />
          No article
        </span>
        <a :href="submission.origin" target="_blank">
          <i class="far fa-fw fa-external-link"></i>
        </a>
      </v-list-tile-sub-title>
    </v-list-tile-content>
  </v-list-tile>
</template>
