<script type="text/babel">
import IsValidationRoot from "@/components/mixins/validation/IsValidationRoot";
import CanValidate from "@/components/mixins/validation/CanValidate";
import SubmissionDialogMixin from "@/components/submission/dialog/SubmissionDialogMixin";
import LeadMixin from "../lead/LeadMixin";
import SubmissionDialog from "@/components/submission/dialog/SubmissionDialog";

export default {
  name: "submission-dialog-lead-autoclaim",
  components: { SubmissionDialog },
  mixins: [LeadMixin, SubmissionDialogMixin, CanValidate, IsValidationRoot],
  props: {
    force: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    confirmAction: "autoclaim-lead",
    title: "force lead autoclaim",
    color: "warning",
    confirmIcon: "",
    confirmText: "force autoclaim",
    cancelIcon: "",
    cancelText: "cancel",
    footer: true
  }),
  created() {
    this.form.leadId = this.lead.id;
    this.form.force = this.force;
  },
  computed: {
    unchangedForm() {
      return {
        leadId: null,
        force: false
      };
    }
  },
  methods: {
    async save() {
      await this.$store.dispatch("submission/autoclaimLead", this.form);
    }
  }
};
</script>

<template>
  <submission-dialog
    dialog-type="submission-dialog-lead-autoclaim"
    header="force lead autoclaim"
    color="warning"
    confirmText="force autoclaim"
    :changed="isChanged"
    :loading="loading"
    @confirm="confirm"
  >
    <p class="body-2">
      This forces the lead to go through autoclaim again. This should only be
      needed occasionally, please inform us if this is not the case.
    </p>
  </submission-dialog>
</template>
