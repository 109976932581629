<template>
  <table-overview
    v-if="!showChild"
    store="submissions"
    title="Submissions"
    icon="far fa-fw fa-clipboard-check"
    :headers="[
      { text: 'Id', value: 'id', type: 'id' },
      { text: 'Name', value: 'full_name', sortable: false },
      { text: 'Website', value: 'website', sortable: false },
      { text: 'Subjects', value: 'subjects', sortable: false },
      { text: 'Leads', value: 'leads', sortable: false },
      { text: 'Completed at', value: 'completed_at' },
      { text: 'Status', value: 'id', sortable: false },
      {
        text: 'Details',
        align: 'center',
        sortable: false,
        class: 'v-table-th--icon',
        rolesOrPermissions: 'submission_view'
      }
    ]"
    searchable
    force-initial-sort
  >
    <export-toolbar-action
      slot="toolbar-actions"
      action="submissions/export"
      permission="submissions_export"
      filename="submissions-%TIME%"
    />
    <submissions-table-row
      slot="table-row"
      slot-scope="{ item }"
      :submission="item"
    />
    <submission-table-filter slot="filters" />
  </table-overview>
  <router-view v-else />
</template>
<script type="text/babel">
import SubmissionsTableRow from "@/components/submission/SubmissionTableRow";
import SubmissionTableFilter from "@/components/submission/SubmissionTableFilter";
import TableOverview from "@/components/generic/overview/TableOverview";
import submissionsModule from "@/store/modules/submissions";
import { mapOverviewLifecycleMethods } from "@/views/helpers";
import ExportToolbarAction from "@/components/generic/overview/ExportToolbarAction";

const overviewLifecycleMethods = mapOverviewLifecycleMethods(
  "submissions",
  submissionsModule
);

export default {
  name: "submissions-overview",
  components: {
    ExportToolbarAction,
    TableOverview,
    SubmissionTableFilter,
    SubmissionsTableRow
  },
  ...overviewLifecycleMethods,
  metaInfo: () => ({
    title: `Submissions`
  }),
  computed: {
    showChild() {
      return (
        this.$route.matched.filter(route => route.name !== undefined).length > 1
      );
    }
  }
};
</script>
