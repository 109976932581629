<template>
  <v-container fluid py-0>
    <v-layout>
      <v-flex xs3 py-0>
        <v-autocomplete-filter v-model="offer.filter" />
      </v-flex>
      <v-flex xs3 py-0>
        <v-autocomplete-subject v-model="offer.subject" job />
      </v-flex>
      <v-flex xs2 py-0>
        <v-text-field
          v-model="price"
          label="Price"
          type="number"
          prepend-icon="far fa-euro-sign"
          :loading="isFetchingPrice"
          :disabled="!isPriceable || isFetchingPrice || isFilterBulditType"
          :hint="customPriceHint"
          persistent-hint
        />
      </v-flex>
      <v-flex xs4 py-0>
        <v-list three-line dense px-0>
          <submission-lead-offer-meta-recipient-tile
            v-if="isOfferable"
            :filter="filter"
            :company="filter.company"
            :subject="subject"
            :price="offer.price"
          >
            <template v-slot:top-action>
              <potential-offer-tile-create-btn
                :potential-offer="potentialOffer"
                :offers="offers"
                :creating-offers="creatingOffers"
                @create="$emit('create', arguments[0])"
              />
            </template>
          </submission-lead-offer-meta-recipient-tile>
          <loading-tile v-else-if="isFetchingPrice" />
          <empty-tile v-else />
        </v-list>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script type="text/babel">
import VAutocompleteFilter from "@/components/filter/VAutocompleteFilter";
import VAutocompleteSubject from "@/components/subject/VAutocompleteSubject";
import { isNil } from "lodash";
import offerApiClient from "@/api/offer";
import SubmissionLeadOfferMetaRecipientTile from "@/components/submission/lead/offer/RecipientTile";
import PotentialOfferTileCreateBtn from "@/components/submission/lead/offer/PotentialOfferTileCreateButton";
import LoadingTile from "@/components/generic/loader/LoadingTile";
import EmptyTile from "@/components/generic/EmptyTile";

export default {
  name: "submission-lead-offers-create-custom",
  components: {
    EmptyTile,
    LoadingTile,
    PotentialOfferTileCreateBtn,
    SubmissionLeadOfferMetaRecipientTile,
    VAutocompleteSubject,
    VAutocompleteFilter
  },
  props: {
    leadId: {
      type: Number,
      required: true
    },
    offers: {
      type: Array,
      required: true
    },
    creatingOffers: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    isFetchingPrice: false,
    isPriceDisabled: false,
    offer: {
      filter: null,
      subject: null,
      price: null
    },
    backendPrice: null,
    filter: null,
    company: null,
    subject: null
  }),
  watch: {
    "offer.filter"() {
      this.filter = this.$store.getters["filter-select/filter"](
        this.offer.filter
      );
      this.fetchOfferPrice();
    },
    "offer.subject"() {
      this.subject = this.$store.state["subjects-select"].entities.subject[
        this.offer.subject
      ];
      this.fetchOfferPrice();
    }
  },
  computed: {
    potentialOffer() {
      const potentialOffer = {
        filter: this.filter,
        subject: this.subject
      };
      if (this.isPriceChanged) {
        potentialOffer.price = this.offer.price;
      }
      return potentialOffer;
    },
    price: {
      get() {
        return this.offer.price;
      },
      set(value) {
        if (isNil(value) || value === "") {
          this.offer.price = 0;
        } else {
          this.offer.price = parseInt(value);
        }
      }
    },
    isFilterBulditType() {
      return isNil(this.filter) ? false : this.filter.type === "BULDIT";
    },
    hasPriceAndSubject() {
      return !isNil(this.offer.filter) && !isNil(this.offer.subject);
    },
    isPriceable() {
      return !isNil(this.offer.filter) && !isNil(this.offer.subject);
    },
    isOfferable() {
      return this.hasPriceAndSubject && !this.isFetchingPrice;
    },
    isPriceChanged() {
      return this.isOfferable && this.backendPrice !== this.offer.price;
    },
    customPriceHint() {
      if (this.isFilterBulditType) {
        return "Can't set price for Buldit filters.";
      }
      if (this.isPriceChanged) {
        return `Originally suggested price was € ${this.backendPrice}.`;
      }
      return undefined;
    }
  },
  methods: {
    async fetchOfferPrice() {
      if (
        !this.isFilterBulditType &&
        !isNil(this.offer.filter) &&
        !isNil(this.offer.subject)
      ) {
        this.isFetchingPrice = true;
        this.offer.price = await offerApiClient.getPrice(
          this.leadId,
          this.offer.filter,
          this.offer.subject
        );
        this.backendPrice = this.offer.price;
        this.isFetchingPrice = false;
      }
    }
  }
};
</script>

<style scoped>
.full-width {
  width: 100%;
}
</style>
