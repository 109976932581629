<script type="text/babel">
import { isNil } from "lodash";
export default {
  name: "submission-field-mixin",
  props: {
    submissionField: {
      type: Object,
      required: true
    }
  },
  data: () => ({}),
  computed: {
    hasOptions() {
      return ["INPUT_RADIO", "SELECT", "INPUT_CHECKBOX"].includes(
        this.submissionField.type
      );
    },
    isMultiple() {
      return this.hasOptions && this.submissionField.type === "INPUT_CHECKBOX";
    },
    selectedOptions() {
      return this.submissionField.options.filter(option => option.isSelected);
    },
    hasValue() {
      return !isNil(this.submissionField.value);
    }
  }
};
</script>
