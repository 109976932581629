<template>
  <v-form>
    <v-text-field v-model="searchDescription" label="Search description" />
    <b-select
      label="States"
      v-model="states"
      multiple
      :items="[
        'rejected',
        'soft-rejected',
        'flagged',
        'incomplete',
        'separated',
        'processed',
        'post-refined',
        'validated',
        'refined',
        'geocoded',
        'pre-refined',
        'unprocessed',
        'queued',
        'delivered'
      ]"
    >
      <template v-slot:selection="{ item, selected }">
        <submission-state-chip :state="item" :selected="selected" />
      </template>
      <template v-slot:item="{ item, tile }">
        <b-selectable-item :selected="tile.props.value">
          <submission-state-chip :state="item" />
        </b-selectable-item>
      </template>
    </b-select>
    <v-autocomplete-company v-model="companies" multiple customer />
    <v-autocomplete-filter v-model="filters" :companies="companies" multiple />
    <v-autocomplete-subject v-model="subjects" multiple />
    <v-switch
      v-if="subjects && subjects.length > 0"
      v-model="subjectsSold"
      label="Subjects sold"
    ></v-switch>
    <v-autocomplete-country v-model="countries" multiple />
    <v-autocomplete-language v-model="languages" multiple />
    <v-autocomplete-form v-model="forms" multiple />
    <v-autocomplete-website v-model="websites" multiple />
    <between-date-input title="Completed At" v-model="date" />
    <between-date-input
      title="Offer Distributed At"
      v-model="offerDistributedAt"
    />
    <fieldset class="primary--border border--width-2">
      <legend class="px-2 ml-3 primary--text text-uppercase">Only</legend>
      <v-container class="pt-0">
        <v-layout row wrap>
          <v-flex xs6>
            <v-checkbox
              v-model="isFlagged"
              label="Flagged"
              color="primary"
              hide-details
            />
          </v-flex>
          <v-flex xs6>
            <v-checkbox
              v-model="isRejected"
              label="Rejected submissions"
              color="primary"
              hide-details
            />
          </v-flex>
          <v-flex xs6>
            <v-checkbox
              v-model="hasUnsoldLeads"
              label="Unsold submissions"
              color="primary"
              hide-details
            />
          </v-flex>
          <v-flex xs6>
            <v-checkbox
              v-model="hasUnsentOffers"
              label="Unsent offers"
              color="primary"
              hide-details
            />
          </v-flex>
          <v-flex xs6>
            <v-checkbox
              v-model="hasRejectionRequestedOffers"
              label="Rejection requested offers"
              color="primary"
              hide-details
            />
          </v-flex>
          <v-flex xs6>
            <v-checkbox
              v-model="hasRejectedOffers"
              label="Rejected offers"
              color="primary"
              hide-details
            />
          </v-flex>
          <v-flex xs6>
            <v-checkbox
              v-model="hasNoSubjects"
              label="No subjects"
              color="primary"
              hide-details
            />
          </v-flex>
          <v-flex xs6>
            <v-checkbox
              v-model="triageable"
              label="Triageable"
              color="primary"
              hide-details
            />
          </v-flex>
        </v-layout>
      </v-container>
    </fieldset>
  </v-form>
</template>
<script type="text/babel">
import VAutocompleteCompany from "@/components/company/VAutocompleteCompany";
import VAutocompleteLanguage from "@/components/language/VAutocompleteLanguage";
import VAutocompleteCountry from "@/components/country/VAutocompleteCountry";
import VAutocompleteSubject from "@/components/subject/VAutocompleteSubject";
import BetweenDateInput from "@/components/generic/input/dates/BetweenDateInput";

import { createNamespacedHelpers } from "@/store/helpers";
import VAutocompleteForm from "@/components/form/VAutocompleteForm";
import VAutocompleteFilter from "@/components/filter/VAutocompleteFilter";
import SubmissionStateChip from "@/components/submission/SubmissionStatusChip";
import BSelect from "@/components/generic/BSelect";
import BSelectableItem from "@/components/generic/BSelectableItem";
import VAutocompleteWebsite from "../website/VAutocompleteWebsite";

const { mapComputedFilters } = createNamespacedHelpers("submissions");

export default {
  name: "submission-table-filter",
  components: {
    VAutocompleteWebsite,
    BSelectableItem,
    BSelect,
    SubmissionStateChip,
    VAutocompleteFilter,
    VAutocompleteForm,
    BetweenDateInput,
    VAutocompleteSubject,
    VAutocompleteCountry,
    VAutocompleteLanguage,
    VAutocompleteCompany
  },
  computed: {
    ...mapComputedFilters([
      "searchDescription",
      "states",
      "subjects",
      "countries",
      "languages",
      "companies",
      "forms",
      "date",
      "offerDistributedAt",
      "isFlagged",
      "isRejected",
      "hasUnsoldLeads",
      "hasUnsentOffers",
      "hasRejectionRequestedOffers",
      "hasRejectionRequestedOffers",
      "hasRejectedOffers",
      "hasNoSubjects",
      "filters",
      "subjectsSold",
      "websites",
      "triageable"
    ])
  }
};
</script>
