<script type="text/babel">
export default {
  name: "submission-fields-base-mixin",
  data: () => ({
    highlightedField: null,
    highlightClass: "grey lighten-4"
  }),
  methods: {
    highlight(field, isActive = true) {
      if (isActive) {
        // If isActive is true, set highlightedField to the field's id.
        this.highlightedField = field.id;
      } else if (this.highlightedField === field.id) {
        // If isActive is false, check if highlightedField already matches the field's id before setting highlighted
        // field to null to prevent race conditions with other fields.
        this.highlightedField = null;
      }
    },
    isHighlighted(field) {
      return this.highlightedField === field.id;
    },
    getHighlightClass(field) {
      return this.isHighlighted(field) ? this.highlightClass : undefined;
    }
  }
};
</script>
