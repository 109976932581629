<script type="text/babel">
import BAlert from "@/components/generic/BAlert";
export default {
  name: "submission-state-soft-rejected",
  components: { BAlert },
  props: {
    store: {
      type: String,
      required: true
    }
  },
  data: () => ({
    cancelling: false
  }),
  computed: {
    hasRejectReason() {
      return this.$store.getters[`${this.store}/hasRejectReason`];
    },
    rejectReason() {
      return this.$store.getters[`${this.store}/rejectReasonNormalized`];
    },
    hasRejectComment() {
      return this.$store.getters[`${this.store}/hasRejectComment`];
    },
    rejectComment() {
      return this.$store.getters[`${this.store}/rejectComment`];
    },
    softRejectedTill() {
      return this.$store.getters[`${this.store}/softRejectedTill`];
    }
  },
  methods: {
    async cancel() {
      this.cancelling = true;
      try {
        await this.$store.dispatch(`${this.store}/cancelReject`);
      } finally {
        this.cancelling = false;
      }
    }
  }
};
</script>

<template>
  <b-alert type="warning" icon="fa-vote-nay" important>
    <div class="headline">
      <span class="warning--text text--darken-2">SOFT REJECTED:</span>
      {{ rejectReason.name }}
    </div>
    <div v-if="hasRejectComment" class="text-pre-line">
      {{ rejectComment }}
    </div>
    <template v-slot:actions>
      <div class="error--text text--darken-2 text-uppercase">
        <div>
          Auto-rejects: {{ softRejectedTill | momentTimestamp($i18n.locale) }}
        </div>
        <div>({{ softRejectedTill | momentFromNow($i18n.locale) }})</div>
      </div>
      <div>
        <v-btn
          class="error"
          :loading="cancelling"
          :disabled="cancelling"
          @click="cancel"
          >CANCEL
        </v-btn>
      </div>
    </template>
  </b-alert>
</template>
