<script type="text/babel">
import { isNil } from "lodash";
import { createNamespacedHelpers } from "vuex";
import { diffString } from "@/helpers/diffstring";
import SubmissionFieldMixin from "@/components/submission/details/fields/SubmissionFieldMixin";

const { mapGetters } = createNamespacedHelpers("submission");

export default {
  name: "submission-field-text",
  mixins: [SubmissionFieldMixin],
  computed: {
    ...mapGetters(["validations", "validationRules", "refinements"]),
    fieldValue() {
      let value =
        this.submissionField.value || this.submissionField.originalValue;
      // If the field is a textarea and the original value is not the same as the current
      // value, edit the value html to visualize the changes.
      if (
        this.submissionField.type === "TEXTAREA" &&
        !isNil(this.submissionField.originalValue) &&
        this.submissionField.originalValue !== value
      ) {
        // Call diffstring method, but replace newlines with whitespace-padded br tags in
        // both the original and the changed string.
        value = diffString(
          this.submissionField.originalValue.replace(/\n/g, " <br> "),
          this.submissionField.value.replace(/\n/g, " <br> ")
        );
        // Replace the whitespace-padded br tags with normal ones.
        value = value.replace(" <br> ", "<br>");
      }
      // If the text triggered any validation rules, make sure to display which words caused it.
      this.validations
        .map(v => v.validationRule)
        .forEach(validationRule => {
          value = this.markHtmlTextWithValidationRule(validationRule, value);
        });
      return value;
    }
  },
  methods: {
    markHtmlTextWithValidationRule(validationRule, text) {
      const triggers = validationRule.triggerGroups.reduce((carry, item) => {
        return carry.concat(item.triggers);
      }, []);
      triggers.forEach(trigger => {
        text = this.markHtmlTextWithTrigger(trigger, text);
      });
      return text;
    },
    markHtmlTextWithTrigger(trigger, text) {
      switch (trigger.type) {
        case "INCLUDES":
          return this.markHtmlTextWithTriggerIncludes(trigger, text);
        default:
          return text;
      }
    },
    markHtmlTextWithTriggerIncludes(trigger, text) {
      if (trigger.isCaseSensitive) {
        trigger.value.forEach(value => {
          text = text.replace(value, `<span class="accent">${value}</span>`);
        });
      } else {
        trigger.value.forEach(value => {
          try {
            const regex = new RegExp(value, "gi");
            text = text.replace(regex, `<span class="accent">${value}</span>`);
          } catch (e) {
            // Do nothing, sometimes value created a bad regular expression. This should be
            // prevented by blocking these kinds of inputs on the trigger level.
            // Until then, just ignore values that would throw an error, as otherwise the
            // description field goes blank every other second.
          }
        });
      }
      return text;
    }
  }
};
</script>

<template>
  <span class="body-1 text-pre-wrap" v-html="fieldValue" />
</template>
