<template>
  <div>
    <template v-for="(telephoneNumberData, index) in telephoneNumbersData">
      <v-chip
        v-if="telephoneNumberData.highlighted"
        :key="index"
        color="secondary lighten-3"
        label
        small
        >{{ telephoneNumberData.number }}
      </v-chip>
      <span v-else :key="index">{{ telephoneNumberData.number }}</span>
      <v-btn
        :key="`${index}-copy`"
        icon
        small
        @click="copyToClipboard(telephoneNumberData.number)"
      >
        <v-icon small>far fa-copy</v-icon>
      </v-btn>
    </template>
  </div>
</template>
<script type="text/babel">
import SubmissionMixin from "@/components/submission/details/SubmissionMixin";
import CopyToClipboardMixin from "@/components/mixins/CopyToClipboardMixin";

export default {
  name: "submission-detail-similar-submissions-row-telephone-numbers",
  mixins: [SubmissionMixin, CopyToClipboardMixin],
  props: {
    currentTelephoneNumbers: {
      type: Array,
      required: true
    }
  },
  computed: {
    telephoneNumbersData() {
      return this.submission.telephoneNumbers.map(telephoneNumber => ({
        number: telephoneNumber.number,
        highlighted: this.currentTelephoneNumbers.includes(telephoneNumber)
      }));
    }
  }
};
</script>
