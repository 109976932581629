<template>
  <submission-dialog
    dialog-type="submission-contact-information-edit"
    header="edit contact information"
    :color="config.color"
    :confirm-text="config.confirmText"
    :confirm-icon="config.confirmIcon"
    :changed="isChanged"
    :loading="loading"
    @confirm="confirm"
  >
    <!-- NAME ROW START -->
    <v-layout row>
      <v-flex xs6 class="pl-3 pr-4">
        <v-layout row>
          <v-flex xs12 sm6>
            <v-text-field
              v-model="form.firstName"
              label="First Name"
              autocomplete="not-my-first-name"
              :error-messages="errors.collect('firstName')"
              data-vv-name="firstName"
              :placeholder="submission.firstName"
              :hint="submission.firstName"
            />
          </v-flex>
          <v-flex xs12 sm6>
            <v-text-field
              v-model="form.lastName"
              label="Last Name"
              autocomplete="not-my-last-name"
              :error-messages="errors.collect('lastName')"
              data-vv-name="lastName"
              :placeholder="submission.lastName"
              :hint="submission.lastName"
            />
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs6 class="pl-4 pr-3">
        <v-layout row>
          <v-flex xs6 sm6>
            <v-text-field
              :value="submission.originalData.firstName"
              label="Original First Name"
              disabled
              readonly
            />
          </v-flex>
          <v-flex xs12 sm6>
            <v-text-field
              :value="submission.originalData.lastName"
              label="Original Last Name"
              disabled
              readonly
            />
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <!-- NAME ROW END -->
    <!-- EMAIL ROW START -->
    <v-layout row>
      <v-flex xs6 class="pl-3 pr-4">
        <v-layout row>
          <v-flex xs12 :class="hasBuddy ? 'warning' : ''">
            <v-text-field
              v-model="form.emailAddress"
              label="Email"
              autocomplete="not-my-email-address"
              :error-messages="errors.collect('emailAddress')"
              data-vv-name="emailAdress"
              type="email"
              :placeholder="submission.emailAddress"
              :hint="submission.emailAddress"
            />
          </v-flex>
          <v-alert :value="isEmailExisting && canMergeUsers" type="warning">
            A user already exists with email address "{{
              existingEmailAddress
            }}". Forcing this change will merge the two users permanently. Only
            use this if you are ABSOLUTELY sure."
          </v-alert>
          <v-alert :value="isEmailExisting && !canMergeUsers" type="error">
            A user already exists with email address "{{
              existingEmailAddress
            }}". You do not have permission to merge users.
          </v-alert>
        </v-layout>
      </v-flex>
      <v-flex xs6 class="pl-4 pr-3">
        <v-layout row>
          <v-flex xs12>
            <v-text-field
              :value="submission.originalData.emailAddress"
              label="Original Email"
              disabled
              readonly
            />
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <!-- EMAIL ROW END -->
    <!-- TELEPHONE NUMBERS ROW START -->
    <v-layout row>
      <v-flex xs6 class="pl-3 pr-4">
        <v-layout row wrap>
          <v-flex
            v-for="(telephoneNumber, index) in form.telephoneNumbers"
            :key="index"
            xs12
          >
            <v-text-field
              v-model="form.telephoneNumbers[index]"
              label="Telephone numbers"
              :append-icon="
                form.telephoneNumbers.length > 1
                  ? 'far fa-times fa-fw'
                  : undefined
              "
              :append-outer-icon="
                index === form.telephoneNumbers.length - 1
                  ? 'far fa-plus fa-fw'
                  : undefined
              "
              @click:append="removeTelephoneNumber(index)"
              @click:append-outer="addTelephoneNumber"
            />
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs6 class="pl-4 pr-3">
        <v-layout row wrap>
          <v-flex
            v-for="(telephoneNumber, index) in submission.originalData
              .telephoneNumbers"
            :key="index"
            xs12
          >
            <v-text-field
              :value="telephoneNumber"
              label="Original telephone number"
              disabled
              readonly
            />
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <!-- TELEPHONE NUMBERS ROW END -->
    <!-- PREFERRED TIME ROW END -->
    <v-layout row>
      <v-flex xs6 class="pl-3 pr-4">
        <v-layout row>
          <template v-if="false">
            <v-flex
              v-for="time in ['MORNING', 'AFTERNOON', 'EVENING', 'WEEKEND']"
              :key="time"
              xs3
            >
              <v-btn
                label
                small
                :class="getPreferredTimeClass(time)"
                @click="setPreferredTime(time)"
              >
                {{ time }}
              </v-btn>
            </v-flex>
          </template>
        </v-layout>
      </v-flex>
      <v-flex xs6 class="pl-4 pr-3"></v-flex>
    </v-layout>
    <!-- PREFERRED TIME ROW END -->
  </submission-dialog>
</template>
<script type="text/babel">
import { isNil } from "lodash";
import { createNamespacedHelpers } from "vuex";
import SubmissionMixin from "@/components/submission/details/SubmissionMixin";
import SubmissionDialog from "@/components/submission/dialog/SubmissionDialog";
import DialogMixin from "@/components/submission/dialog/DialogMixin";

const { mapGetters: mapAuthGetters } = createNamespacedHelpers("auth");

export default {
  name: "submission-contact-information-edit",
  components: { SubmissionDialog },
  mixins: [DialogMixin, SubmissionMixin],
  inject: ["$validator"],
  props: {
    submission: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    config: {
      color: "primary",
      confirmText: undefined,
      confirmIcon: undefined
    },
    existingEmailAddress: null,
    isSaving: false
  }),
  created() {
    this.init();
  },
  computed: {
    ...mapAuthGetters(["hasRolesOrPermissions"]),
    unchangedForm() {
      return {
        firstName: this.submission.firstName,
        lastName: this.submission.lastName,
        emailAddress: this.submission.emailAddress,
        telephoneNumbers: this.submission.telephoneNumbers.map(
          telephoneNumber => telephoneNumber.number
        ),
        preferredTime: this.submission.preferredTime
      };
    },
    isEmailExisting() {
      return !isNil(this.existingEmailAddress);
    },
    canMergeUsers() {
      return this.hasRolesOrPermissions("user_merge");
    },
    isForceMerging() {
      return this.isEmailExisting && this.canMergeUsers;
    }
  },
  methods: {
    init() {
      this.existingEmailAddress = null;
    },
    async save() {
      try {
        await this.$store.dispatch("submission/updateContactDetails", {
          contactDetails: this.form,
          force: this.isForceMerging
        });
        this.existingEmailAddress = null;
        // this.$emit("input", false);
      } catch (exception) {
        const hasPermission = this.hasRolesOrPermissions("user_merge");
        if (
          hasPermission &&
          (exception?.error?.title || exception?.error?.type) ===
            "user_already_exists"
        ) {
          this.existingEmailAddress = this.form.emailAddress;
          this.config = {
            color: "warning",
            confirmText: "Save AND FORCE MERGE",
            confirmIcon: "far fa-exclamation-triangle"
          };
        } else {
          throw exception;
        }
      }
    },
    isLocalPreferredTime(time) {
      return this.form.preferredTime.includes(time);
    },
    getPreferredTimeClass(time) {
      return this.isLocalPreferredTime(time)
        ? "light-green lighten-2 success--text"
        : "grey lighten-3 white--text";
    },
    setPreferredTime(time) {
      let isMorning = this.form.preferredTime.includes("MORNING");
      let isAfternoon = this.form.preferredTime.includes("AFTERNOON");
      let isEvening = this.form.preferredTime.includes("EVENING");
      let isWeekend = this.form.preferredTime.includes("WEEKEND");

      switch (time) {
        case "MORNING":
          isMorning = !isMorning;
          break;
        case "AFTERNOON":
          isAfternoon = !isAfternoon;
          break;
        case "EVENING":
          isEvening = !isEvening;
          break;
        case "WEEKEND":
          isWeekend = !isWeekend;
          break;
      }

      let preferredTimes = [];
      if (isMorning) {
        preferredTimes.push("MORNING");
      }
      if (isAfternoon) {
        preferredTimes.push("AFTERNOON");
      }
      if (isEvening) {
        preferredTimes.push("EVENING");
      }
      if (isWeekend) {
        preferredTimes.push("WEEKEND");
      }

      this.form.preferredTime = preferredTimes;
    },
    addTelephoneNumber() {
      this.form.telephoneNumbers.push("");
    },
    removeTelephoneNumber(index) {
      this.form.telephoneNumbers.splice(index, 1);
    }
  }
};
</script>
