<script type="text/babel">
import { isNil } from "lodash";
import LeadMixin from "@/components/submission/lead/LeadMixin";

export default {
  name: "submission-lead-actions",
  mixins: [LeadMixin],
  props: {
    last: {
      type: Boolean,
      default: false
    },
    createOrPotential: {
      type: Boolean,
      default: false
    },
    autoclaiming: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    isRemoving: false
  }),
  methods: {
    async removeLead() {
      this.isRemoving = true;
      await this.$store.dispatch("submission/removeLead", {
        leadId: this.lead.id
      });
      this.isRemoving = false;
    }
  },
  computed: {
    areActionsDisabled() {
      return this.autoclaiming || this.isRemoving;
    },
    actions() {
      const actions = [];
      if (!this.isAutoclaimed) {
        actions.push({
          text: "Autoclaim",
          color: "warning",
          flat: false,
          loading: this.autoclaiming,
          disabled: this.areActionsDisabled || !this.canAutoclaim,
          click: () => this.$emit("autoclaim", { leadId: this.lead.id })
        });
      }

      if (!this.createOrPotential && this.isAutoclaimed) {
        actions.push({
          text: this.isSaturated ? "review autoclaim" : "create an offer",
          color: "primary",
          flat: false,
          disabled: this.areActionsDisabled,
          click: () => this.$emit("update:create-or-potential", true)
        });
      }

      if (!this.last) {
        actions.push({
          text: "remove lead",
          color: "primary",
          flat: true,
          loading: this.isRemoving,
          disabled: this.areActionsDisabled || !this.isRemovable,
          click: this.removeLead
        });
      }

      return actions;
    },
    headline() {
      if (!this.isAutoclaimed) {
        return "This lead has not been autoclaimed yet.";
      }
      return undefined;
    },
    hasHeadline() {
      return !isNil(this.headline);
    },
    headlineIcon() {
      if (!this.isAutoclaimed) {
        return "far fa-exclamation-triangle warning--text";
      }
      return undefined;
    },
    headlineIconColor() {
      if (!this.isAutoclaimed) {
        return "warning";
      }
      return undefined;
    },
    hasHeadlineIcon() {
      return !isNil(this.headlineIcon);
    }
  }
};
</script>

<template>
  <v-container fluid v-if="actions.length > 0">
    <v-layout row>
      <v-flex xs12>
        <div class="text-xs-center">
          <p class="headline mb-0" v-if="hasHeadline">
            <v-icon v-if="hasHeadlineIcon" :color="headlineIconColor"
              >{{ headlineIcon }}
            </v-icon>
            {{ headline }}
          </p>
          <v-btn
            v-for="(action, index) in actions"
            :key="index"
            :color="action.color"
            :flat="action.flat"
            :loading="action.loading"
            :disabled="action.disabled"
            @click="action.click()"
          >
            {{ action.text }}
          </v-btn>
        </div>
      </v-flex>
    </v-layout>
  </v-container>
</template>
