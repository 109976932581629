<script type="text/babel">
import StaticMap from "vue-static-map";
import SubmissionMixin from "@/components/submission/details/SubmissionMixin";

export default {
  name: "submission-map",
  mixins: [SubmissionMixin],
  props: {
    store: {
      type: String,
      required: true
    }
  },
  components: { StaticMap },
  data: () => ({
    googleApiKey: window.config.google.apiKey
  }),
  computed: {
    mapCenter() {
      return `${this.submission.address.coordinates[0]},${this.submission.address.coordinates[1]}`;
    },
    mapMarkers() {
      return [
        {
          label: "B",
          color: "blue",
          lat: this.submission.address.coordinates[0],
          lng: this.submission.address.coordinates[1],
          size: "normal",
          icon: "img/marker/buldit.png"
        }
      ];
    }
  }
};
</script>

<template>
  <div class="submission-details-map__wrapper">
    <static-map
      class="submission-details-map"
      :google-api-key="googleApiKey"
      :center="mapCenter"
      :markers="mapMarkers"
      :size="[700, 700]"
      :zoom="10"
    />
  </div>
</template>
