<script type="text/babel">
import { findIndex, isNil } from "lodash";
import SubmissionLeadOfferMetaRecipientTile from "@/components/submission/lead/offer/RecipientTile";
import PotentialOfferGroupTile from "./offer/PotentialOfferGroupTile";
import PotentialOfferTileCreateBtn from "@/components/submission/lead/offer/PotentialOfferTileCreateButton";
import LeadMixin from "@/components/submission/lead/LeadMixin";

export default {
  name: "submission-lead-potential-offers",
  mixins: [LeadMixin],
  components: {
    PotentialOfferTileCreateBtn,
    PotentialOfferGroupTile,
    SubmissionLeadOfferMetaRecipientTile
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    autoclaiming: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    creatingOffers: []
  }),
  computed: {
    potentialOffers() {
      return this.lead.potentialOffers
        .map(potentialOffer => potentialOffer)
        .sort((a, b) => a.order - b.order);
    },
    potentialOfferRows() {
      const rows = [];
      this.potentialOffers.forEach(potentialOffer => {
        const index = potentialOffer.order - 1;
        if (isNil(rows[index])) {
          rows[index] = [];
        }
        rows[index].push(potentialOffer);
      });
      return rows;
    }
  },
  methods: {
    async createOffer(column) {
      this.creatingOffers.push({
        filter: column.filter.id,
        subject: column.subject.id
      });
      await this.$store.dispatch("submission/createOffer", {
        leadId: this.lead.id,
        filterId: column.filter.id,
        subjectId: column.subject.id
      });
      const index = findIndex(
        this.creatingOffers,
        item =>
          item.filter === column.filter.id && item.subject === column.subject.id
      );
      this.creatingOffers.splice(index, 1);
    },
    async createOfferGroup(group) {
      group.forEach(offer => {
        this.creatingOffers.push({
          filter: offer.filter.id,
          subject: offer.subject.id
        });
      });
      await this.$store.dispatch("submission/createOfferGroup", {
        leadId: this.lead.id,
        group: group.map(offer => ({
          lead: this.lead.id,
          filter: offer.filter.id,
          subject: offer.subject.id
        }))
      });
      group.forEach(offer => {
        const index = findIndex(
          this.creatingOffers,
          item =>
            item.filter === offer.filter.id && item.subject === offer.subject.id
        );
        this.creatingOffers.splice(index, 1);
      });
    },
    accentClass(potentialOffer) {
      if (this.isPotentialOfferUsedAsBulditFilter(potentialOffer)) {
        return "accent-gradient lighten-5";
      } else if (this.isPotentialOfferUsed(potentialOffer)) {
        return "accent lighten-5";
      }
      return undefined;
    },
    isPotentialOfferUsedAsBulditFilter(potentialOffer) {
      return this.lead.offers.some(offer => {
        return (
          offer.subject.id === potentialOffer.subject.id &&
          !isNil(offer.bulditFilter) &&
          potentialOffer.filter.id === offer.bulditFilter.id
        );
      });
    },
    isPotentialOfferUsed(potentialOffer) {
      return this.lead.offers.some(offer => {
        return (
          offer.subject.id === potentialOffer.subject.id &&
          offer.filter.id === potentialOffer.filter.id
        );
      });
    }
  }
};
</script>

<template>
  <v-container fluid pa-1>
    <v-layout row>
      <v-flex xs12>
        <v-subheader class="submission-details__subheader">
          SUGGESTIONS
          <v-spacer />
          <v-btn icon @click="$emit('input', false)" v-if="isSaturated">
            <v-icon small>far fa-times</v-icon>
          </v-btn>
        </v-subheader>
      </v-flex>
    </v-layout>
    <v-layout row v-if="potentialOfferRows.length > 0">
      <v-flex xs12>
        <v-list three-line dense>
          <template v-for="(row, index) in potentialOfferRows">
            <submission-lead-offer-meta-recipient-tile
              v-if="row.length === 1"
              :key="index"
              :filter="row[0].filter"
              :company="row[0].filter.company"
              :subject="row[0].subject"
              :price="row[0].price"
              :accent="accentClass(row[0])"
              :title="row[0].remark"
            >
              <template v-slot:top-action v-if="!isSaturated">
                <potential-offer-tile-create-btn
                  :potential-offer="row[0]"
                  :offers="lead.offers"
                  :creating-offers="creatingOffers"
                  @create="createOffer(arguments[0])"
                />
              </template>
            </submission-lead-offer-meta-recipient-tile>
            <potential-offer-group-tile
              v-else
              :key="index"
              :group="row"
              :offers="lead.offers"
              :creating-offers="creatingOffers"
              :max-offers="lead.maxOfferCount"
              @create="createOffer(arguments[0])"
              @create-group="createOfferGroup(arguments[0])"
            />
          </template>
        </v-list>
      </v-flex>
    </v-layout>
    <v-layout v-else>
      <v-flex xs12 class="text-xs-center">
        <p class="headline mb-0">
          <i class="far fa-info-square info--text mr-3" />No potential offers
          were found for this lead.
        </p>
        <p class="body-2 grey--text">
          Autoclaimed at
          {{ lead.autoclaimedAt | moment("DD-MM-YYYY HH:mm:ss") }} ({{
            lead.autoclaimedAt | moment("from", "now")
          }})
        </p>
        <v-btn
          color="warning"
          :loading="autoclaiming"
          :disabled="autoclaiming || hasOffers"
          @click="
            $emit('open-dialog', {
              type: 'submission-dialog-lead-autoclaim',
              props: { lead, force: true }
            })
          "
        >
          Force another autoclaim
        </v-btn>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<style lang="scss" scoped>
.primary--border {
  border-color: var(--v-primary-base) !important;
}

.text--underline {
  border-bottom: 2px solid rgba(0, 0, 0, 0.54);
}
</style>
