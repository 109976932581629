<script type="text/babel">
export default {
  name: "now-mixin",
  data: () => ({
    nowCreated: Date.now(),
    now: Date.now(),
    nowInterval: undefined
  }),
  mounted: function() {
    const self = this;
    this.nowInterval = setInterval(() => {
      self.$data.now = Date.now();
    }, 1000);
  },
  beforeDestroy: function() {
    clearInterval(this.nowInterval);
  },
  computed: {
    timestamp: function() {
      var zone = this.$moment().utcOffset();
      var time = this.$moment.utc(this.nowCreated).utcOffset(zone);
      var formatted = time.format("HH:mm MMM Do") + ", " + time.fromNow();

      return formatted;
    }
  },
  methods: {
    getTimestamp(timestamp) {
      const zone = this.$moment().utcOffset();
      const time = this.$moment.utc(timestamp).utcOffset(zone);
      const formatted = time.format("HH:mm MMM Do") + ", " + time.fromNow();

      return formatted;
    },
    getDiffSinceNow(timestamp, unit = "seconds") {
      const zone = this.$moment().utcOffset();
      const time = this.$moment.utc(timestamp).utcOffset(zone);

      return time.diff(this.now, unit);
    }
  }
};
</script>
