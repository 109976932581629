<template>
  <div :class="alertClasses">
    <div class="b-alert__icon" v-if="shouldShowIconSlot">
      <slot name="icon">
        <i
          :class="[
            iconStyle,
            hasIcon ? icon : defaultIcon,
            'fa-fw',
            `fa-${iconSize}x`,
            iconClass || 'b-alert__icon--default'
          ]"
        />
      </slot>
    </div>
    <div class="b-alert__content">
      <slot>
        <template v-if="hasHeader">
          <div
            :class="[
              'b-alert__header',
              headerClass || 'b-alert__header--default'
            ]"
          >
            {{ header }}
          </div>
          <div class="b-alert__message">{{ message }}</div>
        </template>
        <template v-else>
          {{ message }}
        </template>
      </slot>
    </div>
    <div class="b-alert__actions" v-if="hasActionsSlot">
      <slot name="actions" />
    </div>
  </div>
</template>
<script type="text/babel">
import { isNil } from "lodash";

export default {
  name: "b-alert",
  props: {
    type: {
      type: String,
      default: "info",
      // Only accept a value that when lowercase equals either info, success, warning or error.
      validator: value =>
        ["info", "success", "warning", "error"].includes(value.toLowerCase())
    },
    color: {
      type: String,
      required: false
    },
    message: {
      type: String,
      required: false
    },
    header: {
      type: String,
      required: false
    },
    headerClass: {
      type: String,
      required: false
    },
    icon: {
      type: [String, Boolean],
      required: false
    },
    iconStyle: {
      type: String,
      default: "far"
    },
    iconClass: {
      type: String,
      required: false
    },
    iconSize: {
      type: Number,
      default: 2,
      // Only accept a number between - and including - 1 and 10.
      validator: value => value >= 1 && value <= 10
    },
    important: {
      type: Boolean,
      default: false
    }
  },
  created() {},
  computed: {
    /**
     * Returns true if the type property has been provided.
     *
     * @returns {boolean}
     */
    hasType() {
      return !isNil(this.type);
    },
    /**
     * Returns true if the header property has been provided.
     *
     * @returns {boolean}
     */
    hasHeader() {
      return !isNil(this.header);
    },
    shouldShowIconSlot() {
      return this.hasIcon || this.hasDefaultIcon || this.hasIconSlot;
    },
    /**
     * Returns true if the icon property has been provided and it's a non-blank string.
     *
     * @returns {boolean}
     */
    hasIcon() {
      return typeof this.icon === "string" && this.icon.length !== 0;
    },
    /**
     * Returns true if the icon property has been provided and it's either a blank string or a true boolean.
     *
     * @returns {boolean}
     */
    hasDefaultIcon() {
      return this.icon === true || this.icon === "";
    },
    /**
     * Returns true if the icon slot has been provided.
     *
     * @returns {boolean}
     */
    hasIconSlot() {
      return !isNil(this.$slots.icon);
    },
    /**
     * Returns true if the actions slot has been provided.
     *
     * @returns {boolean}
     */
    hasActionsSlot() {
      return !isNil(this.$slots.actions);
    },
    /**
     * Returns the classes that need to be applied to the root tag.
     *
     * @returns {string[]}
     */
    alertClasses() {
      const classes = [
        "b-alert",
        this.color || `b-alert--${this.type.toLowerCase()}`
      ];
      if (this.important) {
        classes.push("b-alert--important");
      }
      return classes;
    },
    /**
     * Returns the default icon class or undefined if none.
     *
     * @returns {string|undefined}
     */
    defaultIcon() {
      switch (this.type.toLowerCase()) {
        case "info":
          return "fa-info-circle";
        case "success":
          return "fa-check-circle";
        case "warning":
          return "fa-exclamation-circle";
        case "error":
          return "fa-times-circle";
        default:
          return undefined;
      }
    }
  },
  methods: {}
};
</script>
