<script type="text/babel">
import SubmissionLeadField from "@/components/submission/lead/fields/Field";
import LeadMixin from "@/components/submission/lead/LeadMixin";
import FieldsBaseMixin from "../FieldsBaseMixin";

export default {
  name: "submission-lead-fields",
  mixins: [LeadMixin, FieldsBaseMixin],
  components: {
    SubmissionLeadField
  }
};
</script>

<template>
  <v-container fluid fill-height class="position--relative" pt-2>
    <v-layout row wrap>
      <v-flex
        v-for="leadField in displayableFields"
        :key="leadField.id"
        xs3
        @mouseover="highlight(leadField)"
        @mouseleave="highlight(leadField, false)"
        :class="getHighlightClass(leadField)"
      >
        <submission-lead-field
          :lead-field="leadField"
          :hover="isHighlighted(leadField)"
          @open-dialog="$emit('open-dialog', arguments[0])"
        />
      </v-flex>
    </v-layout>
  </v-container>
</template>

<style scoped>
.position--relative {
  position: relative;
}
</style>
