<script type="text/babel">
import { isNil } from "lodash";

export default {
  name: "lead-field-mixin",
  props: {
    leadField: {
      type: Object,
      required: true
    }
  },
  computed: {
    hasOptions() {
      return ["INPUT_RADIO", "SELECT", "INPUT_CHECKBOX"].includes(
        this.leadField.type
      );
    },
    hasSubmissionField() {
      return !isNil(this.leadField.submissionField);
    },
    isMultiple() {
      return this.hasOptions && this.leadField.type === "INPUT_CHECKBOX";
    },
    selectedOptions() {
      return this.leadField.options.filter(option => this.isSelected(option));
    },
    fieldValue() {
      if (this.hasOptions) {
        return this.optionFieldValue;
      } else {
        return this.textFieldValue;
      }
    },
    optionFieldValue() {
      const selectedOptionIds = this.selectedOptions.map(option => option.id);

      return this.isMultiple ? selectedOptionIds : selectedOptionIds[0] || null;
    },
    textFieldValue() {
      if (!isNil(this.leadField.value)) {
        return this.leadField.value;
      }

      // If there is no submission field, return null.
      if (!this.hasSubmissionField) {
        return null;
      }

      return this.leadField.submissionField.value;
    }
  },
  methods: {
    isSelected(option) {
      if (isNil(option.isSelected)) {
        return option.submissionFieldOption.isSelected;
      }
      return option.isSelected;
    },
    isInherited(option) {
      return isNil(option.isSelected);
    }
  }
};
</script>
