<script type="text/babel">
import SubmissionContactDefaultTile from "@/components/submission/details/contact/DefaultTile";
import SubmissionContactPreferredTimeTile from "@/components/submission/details/contact/PreferredTimeTile";
import SubmissionMixin from "@/components/submission/details/SubmissionMixin";

export default {
  name: "submission-contact-information",
  mixins: [SubmissionMixin],
  components: {
    SubmissionContactPreferredTimeTile,
    SubmissionContactDefaultTile
  },
  computed: {
    currentTelephoneNumbers() {
      if (this.submission.telephoneNumbers.length > 0) {
        return this.submission.telephoneNumbers
          .map(number => number.number)
          .join(", ");
      }
      return this.originalTelephoneNumbers;
    },
    originalTelephoneNumbers() {
      return this.submission.originalData.telephoneNumbers.join(", ");
    }
  },
  methods: {
    edit() {
      if (this.canEditPersonalDetails) {
        this.$emit("open-dialog", {
          type: "submission-contact-information-edit",
          props: { submission: this.submission, width: "900" }
        });
      }
    }
  }
};
</script>

<template>
  <v-sheet>
    <v-subheader inset class="submission-details__subheader">
      Contact
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" icon small @click="edit">
            <v-icon small>far fa-edit fa-fw</v-icon>
          </v-btn>
        </template>
        <span v-if="canEditPersonalDetails">Override contact information</span>
        <span v-else-if="isQueued || isDelivered || isPostProcessed"
          >This submission is meant for Solvari, so it can't be edited
          anymore</span
        >
        <span v-else-if="!isPreRefined"
          >This submission is still pre-refining, please wait before attempting
          to edit it.</span
        >
        <span v-else
          >This submission has distributed offers, so it can't be edited
          anymore.</span
        >
      </v-tooltip>
    </v-subheader>
    <v-list three-line dense>
      <submission-contact-default-tile
        name="Name"
        :value="fullName"
        :original="
          `${submission.originalData.firstName} ${submission.originalData.lastName}`
        "
        :icon="buddyIcon"
      />
      <submission-contact-default-tile
        name="Email"
        :value="submission.emailAddress"
        :original="submission.originalData.emailAddress"
        icon="far fa-fw fa-at"
        :tileClass="buddyContextClass"
      />
      <submission-contact-default-tile
        name="Telephone Number"
        :value="currentTelephoneNumbers"
        :original="originalTelephoneNumbers"
        icon="far fa-fw fa-phone"
      />
      <submission-contact-preferred-time-tile />
    </v-list>
  </v-sheet>
</template>
