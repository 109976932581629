<template>
  <v-sheet color="orange lighten-4">
    <v-subheader class="submission-details__subheader">
      Geocode
    </v-subheader>
    <submission-geocode-address :store="store" :submission="submission" />
    <submission-geocoder
      :store="store"
      :submission="submission"
      @fetch-geocode="$emit('fetch-geocode', arguments[0])"
    />
    <submission-geocode-list @geocode="$emit('geocode', arguments[0])" />
  </v-sheet>
</template>
<script type="text/babel">
import SubmissionGeocoder from "@/components/submission/details/geocode/Geocoder";
import SubmissionGeocodeAddress from "@/components/submission/details/geocode/Address";
import SubmissionGeocodeList from "@/components/submission/details/geocode/List";
import SubmissionMixin from "@/components/submission/details/SubmissionMixin";

export default {
  name: "submission-geocode",
  mixins: [SubmissionMixin],
  props: {
    store: {
      type: String,
      required: true
    }
  },
  components: {
    SubmissionGeocodeList,
    SubmissionGeocodeAddress,
    SubmissionGeocoder
  }
};
</script>
