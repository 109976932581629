<script type="text/babel">
import SubmissionDialog from "@/components/submission/dialog/SubmissionDialog";
import DialogMixin from "@/components/submission/dialog/DialogMixin";

export default {
  name: "submission-dialog-mark-as-spam",
  components: { SubmissionDialog },
  mixins: [DialogMixin],
  methods: {
    async save() {
      await this.$store.dispatch("submission/markAsSpam");
      this.$router.push({ name: "submissions" });
    }
  }
};
</script>

<template>
  <submission-dialog
    dialog-type="submission-dialog-mark-as-spam"
    header="Flag submission as spam"
    color="error"
    confirmText="delete"
    :changed="true"
    :loading="loading"
    @confirm="confirm"
  >
    <p class="body-2">
      This will delete the submission. Make sure this is what you want.
    </p>
  </submission-dialog>
</template>
