<script type="text/babel">
import SubmissionHeaderActions from "@/components/submission/details/HeaderActions";
import SubmissionMixin from "@/components/submission/details/SubmissionMixin";

export default {
  name: "submission-header",
  mixins: [SubmissionMixin],
  components: { SubmissionHeaderActions },
  props: {
    store: {
      type: String,
      required: true
    },
    open: {
      type: Boolean,
      default: true
    }
  }
};
</script>

<template>
  <v-toolbar color="primary" dark dense class="mb-2">
    <v-toolbar-side-icon @click="$emit('toggle')">
      <v-icon
        >far {{ open ? "fa-clipboard-check" : "fa-chevron-down" }} fa-fw
      </v-icon>
    </v-toolbar-side-icon>
    <v-toolbar-title>
      <span class="text-uppercase secondary--text pr-2"
        ><span class="red--text font-weight-bold pr-3">{{
          submission.state
        }}</span
        >Submission</span
      >
      <span>{{ submission.id }}</span>
    </v-toolbar-title>
    <v-spacer></v-spacer>
    <v-divider vertical inset class="pl-3" />
    <submission-header-actions
      :store="store"
      :submission="submission"
      @open-dialog="$emit('open-dialog', arguments[0])"
    />
  </v-toolbar>
</template>
