<script type="text/babel">
import OfferFieldMixin from "@/components/submission/lead/offer/fields/OfferFieldMixin";
import SubmissionLeadOfferFieldOptions from "@/components/submission/lead/offer/fields/OptionsField";
import SubmissionLeadOfferFieldText from "@/components/submission/lead/offer/fields/TextField";

export default {
  name: "submission-lead-offer-field",
  mixins: [OfferFieldMixin],
  components: { SubmissionLeadOfferFieldText, SubmissionLeadOfferFieldOptions },
  props: {
    hover: {
      type: Boolean,
      default: false
    },
    showAll: {
      type: Boolean,
      required: true
    },
    showingShowAll: {
      type: Boolean,
      required: true
    }
  }
};
</script>

<template>
  <v-layout row wrap>
    <v-flex xs12 py-1 class="pos-relative">
      <v-btn
        icon
        absolute
        right
        :class="{ 'mr-5': showingShowAll }"
        @click="
          $emit('open-dialog', {
            type: 'submission-lead-offer-field-edit',
            props: { offerField }
          })
        "
        v-if="hover"
      >
        <v-icon>far fa-edit</v-icon>
      </v-btn>
      <v-btn icon absolute right v-if="showingShowAll">
        <v-icon
          color="blue lighten-3"
          @click="$emit('update:show-all', !showAll)"
          >far fa-{{ showAll ? "eye" : "eye-slash" }}
        </v-icon>
      </v-btn>
      <span class="grey--text font-weight-bold">
        {{ offerField.label }}
      </span>
    </v-flex>
    <v-flex xs12 py-1>
      <component
        :is="`submission-lead-offer-field-${hasOptions ? 'options' : 'text'}`"
        :offer-field="offerField"
      />
    </v-flex>
  </v-layout>
</template>
