<script type="text/babel">
import { mapGetters, mapState } from "vuex";
import SubmissionDetails from "@/components/submission/Details";
import SubmissionLeads from "@/components/submission/Leads";

import submissionModule from "../../store/modules/submission";
import { mapDetailViewLifecycleMethods } from "../helpers";
import IsValidationRoot from "@/components/mixins/validation/IsValidationRoot";
import SubmissionDialogs from "@/components/submission/SubmissionDialogs";

const detailViewLifecycleMethods = mapDetailViewLifecycleMethods(
  "submission",
  submissionModule
);

export default {
  name: "submission-view",
  mixins: [IsValidationRoot],
  components: {
    SubmissionDialogs,
    SubmissionLeads,
    SubmissionDetails
  },
  data: () => ({
    store: "submission",
    isCreatingLead: false
  }),
  metaInfo() {
    return {
      title: `Submission - ${this.fullName}`
    };
  },
  computed: {
    ...mapState("submission", ["isProcessing"]),
    ...mapGetters({
      fullName: "submission/fullName",
      submission: "submission/submissionNormalized"
    })
  },
  methods: {
    searchFirstName(firstName) {
      this.$store.dispatch("SEARCH_FIRST_NAME", { firstName });
    },
    async createLead() {
      this.isCreatingLead = true;
      await this.$store.dispatch("submission/createLead");
      this.isCreatingLead = false;
    },
    async process() {
      try {
        this.errors.remove({ field: "submission--process" });
        await this.$store.dispatch("submission/process");
      } catch ({ response }) {
        const error = response.data.message;
        this.errors.add({ field: "submission--process", msg: error });
      }
    }
  },
  ...detailViewLifecycleMethods
};
</script>

<template>
  <div>
    <submission-details
      :store="store"
      :submission="submission"
      :processing="isProcessing"
      @submitted="searchFirstName"
      @open-dialog="$store.commit(`${store}/openDialog`, arguments[0])"
      @process="process"
      @validation-ignore="
        $store.dispatch(`${store}/ignoreValidation`, arguments[0])
      "
      @validation-unignore="
        $store.dispatch(`${store}/unignoreValidation`, arguments[0])
      "
      @fetch-geocode="$store.dispatch(`${store}/fetchGeocode`, arguments[0])"
      @geocode="$store.dispatch(`${store}/geocode`, arguments[0])"
    />
    <submission-leads
      :store="store"
      :leads="submission.leads"
      :creatable="submission.isSeparated"
      :creating="isCreatingLead"
      @open-dialog="$store.commit(`${store}/openDialog`, arguments[0])"
      @create="createLead"
    />
    <submission-dialogs :store="store" />
  </div>
</template>
