<template>
  <submission-dialog
    dialog-type="submission-dialog-address-edit"
    header="address information"
    :changed="isChanged"
    :loading="loading"
    @confirm="confirm"
  >
    <v-layout wrap>
      <v-flex xs12 sm12 md7>
        <v-text-field
          v-model="form.street"
          v-validate="'required|max:255'"
          data-vv-name="street"
          :error-messages="errors.collect('street')"
          label="Street"
          :placeholder="address.street"
          :hint="originalAddress.street"
          persistent-hint
        />
      </v-flex>
      <v-flex xs12 sm7 md3>
        <v-text-field
          v-model="form.streetNumber"
          v-validate="'required|max:255'"
          data-vv-name="streetNumber"
          :error-messages="errors.collect('streetNumber')"
          label="Number"
          :placeholder="address.number"
          :hint="originalAddress.number"
          persistent-hint
        />
      </v-flex>
      <v-flex xs12 sm5 md2>
        <v-text-field
          v-if="!form.isBoxBlankForced"
          v-model="form.box"
          :error-messages="errors.collect('isBoxBlankForced')"
          label="Box"
          :placeholder="address.box"
          :hint="originalAddress.box"
          persistent-hint
        />
      </v-flex>
      <v-flex xs12>
        <v-text-field
          v-model="form.postalCode"
          v-validate="'required|max:255'"
          data-vv-name="postalCode"
          :error-messages="errors.collect('postalCode')"
          label="Postal Code"
          disabled
        />
      </v-flex>
      <v-flex xs12>
        <v-text-field
          v-model="form.city"
          label="City"
          :placeholder="address.locality"
          :hint="originalAddress.city"
          disabled
        />
      </v-flex>
      <v-flex xs12>
        <v-checkbox v-model="form.isBoxBlankForced" label="Hide street box" />
      </v-flex>
    </v-layout>
  </submission-dialog>
</template>
<script type="text/babel">
import SubmissionDialog from "@/components/submission/dialog/SubmissionDialog";
import DialogMixin from "@/components/submission/dialog/DialogMixin";

export default {
  name: "submission-dialog-address-edit",
  components: { SubmissionDialog },
  mixins: [DialogMixin],
  props: {
    address: {
      type: Object,
      required: true
    },
    originalAddress: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    confirmAction: "update-address",
    title: "address information"
  }),
  computed: {
    unchangedForm() {
      return {
        street: this.address.street,
        streetNumber: this.address.streetNumber,
        box: this.address.box,
        postalCode: this.address.postalCode,
        locality: this.address.locality,
        isBoxBlankForced: this.address.isBoxBlankForced
      };
    }
  },
  methods: {
    async save() {
      await this.$store.dispatch("submission/updateAddress", this.form);
    }
  }
};
</script>
