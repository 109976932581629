import { render, staticRenderFns } from "./CreateCustomOffer.vue?vue&type=template&id=67d78d15&scoped=true&"
import script from "./CreateCustomOffer.vue?vue&type=script&lang=js&"
export * from "./CreateCustomOffer.vue?vue&type=script&lang=js&"
import style0 from "./CreateCustomOffer.vue?vue&type=style&index=0&id=67d78d15&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67d78d15",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VContainer,VFlex,VLayout,VList,VTextField})
