<script type="text/babel">
import { isNil } from "lodash";

export default {
  name: "submission-details-form-tile",
  props: {
    form: {
      type: Object,
      default: null
    }
  },
  computed: {
    hasForm() {
      return !isNil(this.form);
    }
  }
};
</script>

<template>
  <v-list-tile>
    <v-list-tile-content>
      <v-list-tile-title>Form</v-list-tile-title>
      <v-list-tile-sub-title>
        <template v-if="hasForm">
          <i class="far fa-fw fa-clipboard pr-2" />
          <router-link
            :to="{ name: 'forms.show', params: { formId: form.id } }"
          >
            {{ form.name }}
          </router-link>
        </template>
        <span v-else class="error--text text-uppercase">
          <i class="far fa-fw fa-clipboard" />
          No form matched
        </span>
      </v-list-tile-sub-title>
    </v-list-tile-content>
  </v-list-tile>
</template>
