<script type="text/babel">
import SubmissionContactDefaultTile from "./contact/DefaultTile";
import SubmissionMixin from "@/components/submission/details/SubmissionMixin";

export default {
  name: "submission-location",
  mixins: [SubmissionMixin],
  props: {
    store: {
      type: String,
      required: true
    }
  },
  components: { SubmissionContactDefaultTile },
  data: () => ({
    resetting: false
  }),
  computed: {
    canEdit() {
      return this.canEditLocation && !this.editing;
    },
    editing() {
      return (
        this.$store.state[this.store].processing.addressEdit ||
        this.$store.state[this.store].processing.addressReset
      );
    }
  },
  methods: {
    override() {
      if (this.canEdit) {
        this.$emit("open-dialog", {
          type: "submission-dialog-address-edit",
          props: {
            // Make sure to add submission's isBoxBlankForced to the address
            address: {
              street: this.submission.address.street,
              streetNumber: this.submission.address.streetNumber,
              box: this.submission.address.box,
              postalCode: this.submission.address.postalCode,
              locality: this.submission.address.locality,
              isBoxBlankForced: this.submission.isBoxBlankForced
            },
            originalAddress: {
              street: this.submission.originalData.street,
              streetNumber: this.submission.originalData.streetNumber,
              box: this.submission.originalData.box,
              postalCode: this.submission.originalData.postalCode,
              locality: this.submission.originalData.city
            }
          }
        });
      }
    },
    async reset() {
      if (this.canEdit) {
        this.resetting = true;
        await this.$store.dispatch(`${this.store}/resetAddress`);
        this.resetting = false;
      }
    }
  }
};
</script>

<template>
  <v-sheet color="secondary" dark>
    <v-subheader class="submission-details__subheader">
      Location
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" icon small @click="override">
            <v-icon small
              >{{
                editing && !resetting
                  ? "far fa-cog fa-fw fa-spin"
                  : "far fa-edit fa-fw"
              }}
            </v-icon>
          </v-btn>
        </template>
        <span v-if="canEdit">Override address</span>
        <span v-else-if="isQueued"
          >This submission is queued for delivery to Solvari, so it can't be
          edited anymore</span
        >
        <span v-else-if="isDelivered"
          >This submission has been delivered to Solvari, so it can't be edited
          anymore</span
        >
        <span v-else
          >Can NOT override address while there are distributed offers.</span
        >
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" icon small @click="reset">
            <v-icon small
              >{{
                editing && resetting
                  ? "far fa-cog fa-fw fa-spin"
                  : "far fa-times fa-fw"
              }}
            </v-icon>
          </v-btn>
        </template>
        <span v-if="canEdit">Reset address</span>
        <span v-else-if="isQueued"
          >This submission is queued for delivery to Solvari, so it can't be
          edited anymore</span
        >
        <span v-else-if="isDelivered"
          >This submission has been delivered to Solvari, so it can't be edited
          anymore</span
        >
        <span v-else
          >Can NOT reset address while there are distributed offers.</span
        >
      </v-tooltip>
    </v-subheader>
    <v-list three-line dense class="secondary">
      <submission-contact-default-tile
        name="Street"
        :value="addressLine1"
        :original="addressLine1Original"
      />
      <submission-contact-default-tile
        name="City"
        :value="addressLine2"
        :original="addressLine2Original"
      />
      <submission-contact-default-tile
        name="Province & Country"
        :value="`${provinceName}, ${countryName}`"
      />
    </v-list>
  </v-sheet>
</template>
