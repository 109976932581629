<template>
  <tr>
    <td>
      {{ submission.id }}
      <v-chip v-if="current" color="primary" dark label small>CURRENT</v-chip>
    </td>
    <td>
      <span>{{ submission.fullName }}</span>
    </td>
    <td>
      <v-chip v-if="isEmailCurrent" color="secondary lighten-3" label small
        >{{ submission.emailAddress }}
      </v-chip>
      <span v-else>{{ submission.emailAddress }}</span>
      <v-btn icon small @click="copyToClipboard(submission.emailAddress)">
        <v-icon small>far fa-copy</v-icon>
      </v-btn>
    </td>
    <td>
      <submission-detail-similar-submissions-row-telephone-numbers
        :submission="submission"
        :current-telephone-numbers="currentTelephoneNumbers"
      />
    </td>
    <td>
      <v-chip-subject
        v-for="subject in submission.subjects"
        :key="subject.id"
        :subject="subject"
      />
    </td>
    <td>
      <offer-state-chip
        v-for="offer in offers"
        :key="offer.id"
        :offer="offer"
      />
    </td>
    <td>
      {{ submission.completedAtCorrect | momentFromNow($i18n.locale) }}
    </td>
    <td>
      <submission-state-chip :state="submission.state" />
    </td>
    <td>
      <v-btn
        icon
        :disabled="current"
        :to="{
          name: 'submissions.show',
          params: { submissionId: submission.id }
        }"
      >
        <v-icon>far fa-search</v-icon>
      </v-btn>
    </td>
  </tr>
</template>
<script type="text/babel">
import { DateTime } from "luxon";
import { isNil } from "lodash";
import SubmissionMixin from "@/components/submission/details/SubmissionMixin";
import SubmissionDetailSimilarSubmissionsRowTelephoneNumbers from "@/components/submission/details/SimilarSubmissionsRowTelephoneNumber";
import CopyToClipboardMixin from "@/components/mixins/CopyToClipboardMixin";
import OfferStateChip from "@/components/offer/OfferStateChip";
import SubmissionStateChip from "@/components/submission/SubmissionStatusChip";
import VChipSubject from "../../subject/VChipSubject";

export default {
  name: "submission-detail-similar-submissions-row",
  mixins: [SubmissionMixin, CopyToClipboardMixin],
  components: {
    VChipSubject,
    SubmissionStateChip,
    OfferStateChip,
    SubmissionDetailSimilarSubmissionsRowTelephoneNumbers
  },
  props: {
    current: {
      type: Boolean,
      default: false
    },
    currentEmail: {
      type: String,
      required: true
    },
    currentTelephoneNumbers: {
      type: Array,
      required: true
    }
  },
  computed: {
    rowClass() {
      if (this.submission.isRejected) {
        return "error";
      } else if (!this.isProcessed) {
        return "warning";
      } else {
        return this.highlight;
      }
    },
    cellClass() {
      if (this.submission.isRejected) {
        return "white--text";
      }
      return "grey--text";
    },
    isEmailCurrent() {
      return this.submission.emailAddress === this.currentEmail;
    },
    emailClass() {
      if (this.submission.emailAddress === this.currentEmail) {
        return "secondary lighten-3 black--text pa-1";
      }
      return undefined;
    },
    isProcessed() {
      return (
        this.submission.isGeocoded &&
        this.submission.isRefined &&
        this.submission.isValidated &&
        this.submission.isSeparated
      );
    },
    statusIcon() {
      if (this.isProcessed) {
        return "far fa-check";
      }
      if (this.isRejected) {
        return "far fa-trash";
      }
      return "far fa-exclamation-triangle";
    },
    statusIconClass() {
      if (this.isProcessed) {
        return "success--text";
      }
      if (this.isRejected) {
        return "error--text";
      }
      return "warning--text";
    },
    hasStatusIcon() {
      return !isNil(this.statusIcon);
    }
  },
  methods: {
    localizeDateTime(timestamp) {
      return new DateTime.fromISO(timestamp).toFormat("yyyy-LL-dd HH:mm:ss");
    }
  }
};
</script>
