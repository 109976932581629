<script type="text/babel">
export default {
  name: "submission-lead-offer-meta-distribute-tile",
  props: {
    processing: {
      type: Boolean,
      default: false
    },
    distributing: {
      type: Boolean,
      default: false
    },
    removing: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<template>
  <v-list-tile>
    <v-list-tile-content>
      <v-container fluid>
        <v-layout row>
          <v-flex class="text-xs-center" xs6>
            <v-btn
              flat
              :disabled="processing || distributing || removing"
              :loading="distributing"
              @click="$emit('distribute')"
            >
              Distribute
            </v-btn>
          </v-flex>
          <v-flex class="text-xs-center" xs6>
            <v-btn
              color="grey"
              flat
              :disabled="processing || distributing || removing"
              :loading="removing"
              @click="$emit('remove')"
            >
              Cancel
            </v-btn>
          </v-flex>
        </v-layout>
      </v-container>
    </v-list-tile-content>
  </v-list-tile>
</template>
