<script type="text/babel">
import LeadFieldMixin from "./LeadFieldMixin";

export default {
  name: "submission-lead-field-text",
  mixins: [LeadFieldMixin]
};
</script>

<template>
  <span class="body-1 text-pre-wrap" v-html="fieldValue" />
</template>
