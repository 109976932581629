<script type="text/babel">
import { find } from "lodash";

export default {
  name: "potential-offer-tile-create-btn",
  props: {
    potentialOffer: {
      type: Object,
      required: true
    },
    offers: {
      type: Array,
      required: true
    },
    creatingOffers: {
      type: Array,
      required: true
    }
  },
  computed: {
    offerFilterIds() {
      return this.offers.map(offer => offer.filter);
    },
    isCreated() {
      return this.offerFilterIds.includes(this.potentialOffer.filter);
    },
    isBeingCreated() {
      return (
        find(
          this.creatingOffers,
          item =>
            item.filter === this.potentialOffer.filter.id &&
            item.subject === this.potentialOffer.subject.id
        ) !== undefined
      );
    }
  }
};
</script>

<template>
  <v-btn
    small
    @click="
      $emit('create', {
        filter: potentialOffer.filter,
        subject: potentialOffer.subject,
        price: potentialOffer.price
      })
    "
    :loading="isBeingCreated"
    :disabled="isCreated || isBeingCreated || creatingOffers.length > 0"
  >
    {{ isCreated ? "USED" : "ADD" }}
  </v-btn>
</template>
