<script type="text/babel">
import VAutocompleteSubject from "../../../subject/VAutocompleteSubject";
import SubmissionDialog from "@/components/submission/dialog/SubmissionDialog";
import DialogMixin from "@/components/submission/dialog/DialogMixin";

export default {
  name: "submission-lead-subjects-edit",
  components: { SubmissionDialog, VAutocompleteSubject },
  mixins: [DialogMixin],
  props: {
    lead: {
      type: Object,
      required: true
    }
  },
  computed: {
    unchangedForm() {
      return {
        leadId: this.lead.id,
        subjects: this.lead.subjects.map(s => s.id)
      };
    }
  },
  methods: {
    async save() {
      await this.$store.dispatch("submission/updateLeadSubjects", this.form);
    }
  }
};
</script>

<template>
  <submission-dialog
    dialog-type="submission-lead-subjects-edit"
    header="edit subjects"
    :changed="isChanged"
    :loading="loading"
    @confirm="confirm"
  >
    <v-autocomplete-subject
      v-model="form.subjects"
      name="subjects"
      job
      multiple
      required
      v-validate="'required'"
      data-vv-name="subjects"
      :error-messages="errors.collect('subjects')"
    />
  </submission-dialog>
</template>
