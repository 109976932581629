var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.showChild)?_c('table-overview',{attrs:{"store":"submissions","title":"Submissions","icon":"far fa-fw fa-clipboard-check","headers":[
    { text: 'Id', value: 'id', type: 'id' },
    { text: 'Name', value: 'full_name', sortable: false },
    { text: 'Website', value: 'website', sortable: false },
    { text: 'Subjects', value: 'subjects', sortable: false },
    { text: 'Leads', value: 'leads', sortable: false },
    { text: 'Completed at', value: 'completed_at' },
    { text: 'Status', value: 'id', sortable: false },
    {
      text: 'Details',
      align: 'center',
      sortable: false,
      class: 'v-table-th--icon',
      rolesOrPermissions: 'submission_view'
    }
  ],"searchable":"","force-initial-sort":""},scopedSlots:_vm._u([{key:"table-row",fn:function(ref){
  var item = ref.item;
return _c('submissions-table-row',{attrs:{"submission":item}})}}],null,false,3814084502)},[_c('export-toolbar-action',{attrs:{"slot":"toolbar-actions","action":"submissions/export","permission":"submissions_export","filename":"submissions-%TIME%"},slot:"toolbar-actions"}),_c('submission-table-filter',{attrs:{"slot":"filters"},slot:"filters"})],1):_c('router-view')}
var staticRenderFns = []

export { render, staticRenderFns }