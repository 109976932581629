<script type="text/babel">
import SubmissionFieldMixin from "@/components/submission/details/fields/SubmissionFieldMixin";

export default {
  name: "submission-field-options",
  mixins: [SubmissionFieldMixin],
  computed: {
    options() {
      if (!this.onlySelected) {
        return this.submissionField.options;
      }
      return this.submissionField.options.filter(option => option.isSelected);
    }
  }
};
</script>

<template>
  <ul class="fa-ul ml-4">
    <li
      v-for="option in options"
      :key="option.id"
      :class="{
        'grey--text text--lighten-1': !option.isSelected
      }"
    >
      <span class="fa-li">
        <i
          :class="{
            'fas fa-check-square': option.isSelected,
            'far fa-square': !option.isSelected,
            'accent--text': option.isSelected !== option.isOriginallySelected
          }"
        />
      </span>
      {{ option.label }}
    </li>
  </ul>
</template>
