<script type="text/babel">
import { createNamespacedHelpers } from "vuex";

const { mapGetters } = createNamespacedHelpers("submission");

export default {
  name: "submission-details-preferred-time-tile",
  data: () => ({
    times: {
      MORNING: "MORN",
      AFTERNOON: "NOON",
      EVENING: "EVEN",
      WEEKEND: "WKND"
    }
  }),
  computed: {
    ...mapGetters(["preferredTime"])
  }
};
</script>

<template>
  <v-list-tile>
    <v-list-tile-action>
      <v-icon>far fa-fw fa-clock</v-icon>
    </v-list-tile-action>
    <v-list-tile-content>
      <v-list-tile-sub-title>
        <v-chip
          v-for="(value, key) in times"
          :key="key"
          :class="
            preferredTime.indexOf(key) > -1
              ? 'light-green lighten-2 success--text'
              : 'grey lighten-3 white--text'
          "
          label
          small
        >
          {{ value }}
        </v-chip>
      </v-list-tile-sub-title>
    </v-list-tile-content>
  </v-list-tile>
</template>
