<script type="text/babel">
export default {
  name: "submission-filter-mixin",
  props: {
    filter: {
      type: Object,
      required: true
    }
  },
  computed: {
    isBulditFilter() {
      return this.filter.type === "BULDIT";
    },
    isNonPriorityBulditFilter() {
      return this.filter.type === "BULDIT" && !this.isPriority;
    },
    isPriorityBulditFilter() {
      return this.filter.type === "BULDIT" && this.isPriority;
    },
    isResellerFilter() {
      return !this.isBulditFilter && this.company.isReseller;
    },
    isPriority() {
      return this.filter.isPriority;
    }
  }
};
</script>
