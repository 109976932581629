<script type="text/babel">
import { isNil } from "lodash";

export default {
  name: "offer-field-mixin",
  props: {
    offerField: {
      type: Object,
      required: true
    }
  },
  data: () => ({}),
  computed: {
    hasOptions() {
      return ["INPUT_RADIO", "SELECT", "INPUT_CHECKBOX"].includes(
        this.offerField.type
      );
    },
    isMultiple() {
      return this.hasOptions && this.offerField.type === "INPUT_CHECKBOX";
    },
    selectedOptions() {
      return this.offerField.options.filter(option => {
        return this.isSelected(option);
      });
    },
    fieldValue() {
      return this.hasOptions ? this.optionFieldValue : this.textFieldValue;
    },
    optionFieldValue() {
      const selectedOptionIds = this.selectedOptions.map(option => option.id);
      return this.isMultiple ? selectedOptionIds : selectedOptionIds[0] || null;
    },
    textFieldValue() {
      if (!isNil(this.offerField.value)) {
        return this.offerField.value;
      }
      if (
        !isNil(this.offerField.leadField) &&
        !isNil(this.offerField.leadField.value)
      ) {
        return this.offerField.leadField.value;
      }

      return isNil(this.offerField.submissionField)
        ? null
        : this.offerField.submissionField.value;
    }
  },
  methods: {
    isSelected(option) {
      // If this offer field option is selected is true or false, that's the end of it.
      if (!isNil(option.isSelected)) {
        return option.isSelected;
      }

      // If not, but it has no lead field option, default to false.
      if (isNil(option.leadFieldOption)) {
        return false;
      }

      // If it has a lead field option and that lead field option's is selected is either true or false, return that.
      if (!isNil(option.leadFieldOption.isSelected)) {
        return option.leadFieldOption.isSelected;
      }

      // If not, but the lead field option has no submission field option, default to false.
      if (isNil(option.leadFieldOption.submissionFieldOption)) {
        return false;
      }

      // Otherwise, return the offer field option's lead field option's submission field option's isSelected property.
      return option.leadFieldOption.submissionFieldOption.isSelected;
    }
  }
};
</script>
