<template>
  <div class="b-selectable-item">
    <div class="b-selectable-item__selection">
      <v-checkbox :value="selected" />
    </div>
    <div class="b-selectable-item__content">
      <slot>No content provided</slot>
    </div>
  </div>
</template>

<script type="text/babel">
export default {
  name: "b-selectable-item",
  props: {
    selected: {
      type: Boolean,
      required: false
    }
  }
};
</script>

<style scoped>
.b-selectable-item {
  display: flex;
  height: 100%;
  text-align: left;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  overflow: hidden;
}

.b-selectable-item__selection {
  flex-grow: 0 !important;
  display: flex;
  -webkit-box-align: start;
  align-items: flex-start;
  -webkit-box-pack: center;
  justify-content: center;
  flex-direction: column;
}

.b-selectable-item__content {
  flex-grow: 1 !important;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-direction: column;
  flex-direction: column;
}
</style>
