<script type="text/babel">
import SubmissionLeadOfferFields from "@/components/submission/lead/offer/Fields";
import SubmissionLeadOfferMeta from "@/components/submission/lead/offer/Meta";
import SubmissionLeadOfferRejection from "@/components/submission/lead/offer/Rejection";
import OfferMixin from "@/components/submission/lead/offer/OfferMixin";

export default {
  name: "submission-lead-offer-details",
  mixins: [OfferMixin],
  components: {
    SubmissionLeadOfferRejection,
    SubmissionLeadOfferMeta,
    SubmissionLeadOfferFields
  },
  props: {
    lead: {
      type: Object,
      required: true
    },
    processing: {
      type: Boolean,
      required: true
    },
    distributing: {
      type: Boolean,
      required: true
    },
    removing: {
      type: Boolean,
      required: true
    },
    rejecting: {
      type: Boolean,
      required: true
    },
    denying: {
      type: Boolean,
      required: true
    }
  }
};
</script>

<template>
  <v-sheet color="blue lighten-5">
    <v-container fluid>
      <v-layout>
        <v-flex xs9 pa-0>
          <submission-lead-offer-rejection
            :offer="offer"
            v-if="isRejectionRequested || isRejected"
          />
          <submission-lead-offer-fields
            :offer="offer"
            @open-dialog="$emit('open-dialog', arguments[0])"
          />
        </v-flex>
        <v-flex xs3 :class="contextColor" pa-0>
          <submission-lead-offer-meta
            :offer="offer"
            :offer-id="offer.id"
            :processing="processing"
            :distributing="distributing || !!offer.queuedForDeliveryAt"
            :removing="removing"
            :rejecting="rejecting"
            :denying="denying"
            @distribute="$emit('distribute')"
            @remove="$emit('remove')"
            @reject="$emit('reject', arguments[0])"
            @deny-rejection="$emit('deny-rejection', arguments[0])"
          />
        </v-flex>
      </v-layout>
    </v-container>
  </v-sheet>
</template>
