<script type="text/babel">
import LeadFieldMixin from "@/components/submission/lead/fields/LeadFieldMixin";
import SubmissionLeadFieldOptions from "@/components/submission/lead/fields/OptionsField";
import SubmissionLeadFieldText from "@/components/submission/lead/fields/TextField";

export default {
  name: "submission-lead-field",
  mixins: [LeadFieldMixin],
  components: { SubmissionLeadFieldText, SubmissionLeadFieldOptions },
  props: {
    hover: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<template>
  <v-layout row wrap>
    <v-flex xs12 py-1 class="pos-relative">
      <v-btn
        icon
        absolute
        right
        :class="{ 'mr-5': false }"
        @click="
          $emit('open-dialog', {
            type: 'submission-lead-field-edit',
            props: { leadField }
          })
        "
        v-if="hover"
      >
        <v-icon>far fa-edit</v-icon>
      </v-btn>
      <span class="grey--text font-weight-bold">
        {{ leadField.label }}
      </span>
    </v-flex>
    <v-flex xs12 py-1>
      <component
        :is="`submission-lead-field-${hasOptions ? 'options' : 'text'}`"
        :lead-field="leadField"
      />
    </v-flex>
  </v-layout>
</template>
