<script type="text/babel">
import { isNil } from "lodash";
import OfferMixin from "@/components/submission/lead/offer/OfferMixin";

export default {
  name: "submission-lead-offer-rejection",
  mixins: [OfferMixin],
  props: {
    offer: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    rejectionReason: null,
    rejectionBulditComment: null
  }),
  created() {
    if (!isNil(this.offer.rejectionReason)) {
      this.rejectionReason = this.offer.rejectionReason.id;
    }
  },
  computed: {
    hasRejectionProcessedBy() {
      return !isNil(this.offer.rejectionAcceptedBy);
    },
    rejectionState() {
      return this.isRejected ? "rejected" : "rejection denied";
    },
    rejectionProcessedBy() {
      if (this.hasRejectionProcessedBy) {
        return `by ${this.offer.rejectionAcceptedBy.firstName} ${this.offer.rejectionAcceptedBy.lastName}`;
      }
      return "automatically";
    },
    alertColor() {
      switch (this.state) {
        case "REJECTED":
          return "error";
        case "REJECTION_REQUESTED":
          return "warning";
        default:
          return "grey";
      }
    },
    alertIcon() {
      switch (this.state) {
        case "REJECTED":
          return "far fa-times-octagon";
        case "REJECTION_REQUESTED":
          return "far fa-exclamation-circle";
        default:
          return "far fa-info";
      }
    },
    mutedTextColor() {
      switch (this.state) {
        case "REJECTED":
          return "error--text text--lighten-2";
        case "REJECTION_REQUESTED":
          return "warning--text text--lighten-2";
        default:
          return "grey--text text--darken-1";
      }
    }
  }
};
</script>

<template>
  <v-container fluid py-0 pl-0 pr-2>
    <v-layout row wrap>
      <v-flex xs12>
        <v-alert
          class="mt-0"
          o
          :value="isRejectionRequested || isRejected"
          :color="alertColor"
          :icon="alertIcon"
          transition="scale-transition"
        >
          <div class="title text-uppercase">
            {{ offer.rejectionReason.label.nl_be }}
          </div>
          <div class="body-1 white--text text--darken-2">
            {{ offer.rejectionComment }}
          </div>
          <template v-if="isRejectionDenied || isRejected">
            <div class="buldit-reply pt-1 mt-1 body-2">
              {{ offer.rejectionBulditComment }}
            </div>
            <div :class="['text-uppercase', mutedTextColor]">
              <span class="offer-rejection__processed-by-state">{{
                rejectionState
              }}</span
              >&nbsp;<span class="offer-rejection__processed-by">{{
                rejectionProcessedBy
              }}</span>
            </div>
          </template>
        </v-alert>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<style scoped>
.buldit-reply {
  border-top: 1px solid white;
}
</style>
