<script type="text/babel">
import { isNil } from "lodash";

export default {
  name: "submission-lead-mixin",
  props: {
    lead: {
      type: Object,
      required: true
    }
  },
  computed: {
    hasOffers() {
      return this.lead.offers.length > 0;
    },
    hasDistributedOffers() {
      return this.lead.offers.some(offer => !isNil(offer.distributedAt));
    },
    isSaturated() {
      return this.lead.offers.length === this.maxOfferCount;
    },
    maxOfferCount() {
      return this.hasExclusiveOffer ? 1 : this.lead.maxOfferCount;
    },
    validOffers() {
      return this.lead.offers.filter(offer => isNil(offer.rejectedAt));
    },
    validOfferCount() {
      return this.validOffers.length;
    },
    hasExclusiveOffer() {
      return this.lead.offers.some(o => o.isExclusive);
    },
    /**
     * @deprecated in favor of isSaturated
     * @returns {boolean}
     */
    isLeadSaturated() {
      return this.lead.offers.length === this.lead.maxOfferCount;
    },
    isAutoclaimed() {
      return !isNil(this.lead.autoclaimedAt);
    },
    /**
     * @deprecated as it serves no purpose, just use this.lead.autoclaimAt
     */
    autoclaimedAt() {
      return this.lead.autoclaimedAt;
    },
    canAutoclaim() {
      return !this.isAutoclaimed && !this.hasOffers;
    },
    isRemovable() {
      return !this.hasOffers;
    },

    // STATE

    contextColor() {
      return this.isAutoclaimed ? "primary" : "warning";
    },
    contextSecondaryColor() {
      return this.isAutoclaimed ? "secondary" : "warning darken-2";
    },
    contextSecondaryClass() {
      return this.isAutoclaimed
        ? "secondary--text"
        : "warning--text text--darken-2";
    },

    // FIELDS
    alwaysFields() {
      return this.lead.fields.filter(field => field.group === "ALWAYS");
    },
    subjectFields() {
      return this.lead.fields.filter(field => field.group === "SUBJECT");
    },
    contactFields() {
      return this.lead.fields.filter(field => field.group === "CONTACT");
    },
    displayableFields() {
      return this.alwaysFields.concat(this.subjectFields);
    },
    defaultDesiredStartField() {
      return this.lead.fields.filter(
        field => field.name === "default_desired_start"
      );
    },
    defaultDescriptionField() {
      return this.lead.fields.filter(
        field => field.name === "default_description"
      );
    },
    defaultOwnershipField() {
      return this.lead.fields.filter(
        field => field.name === "default_ownership"
      );
    }
  }
};
</script>
