<template>
  <v-container fluid>
    <v-layout wrap>
      <v-flex xs12 pa-0>
        <v-sheet color="blue lighten-5">
          <v-btn
            small
            icon
            :disabled="hasOffers"
            @click="
              $emit('open-dialog', {
                type: 'submission-lead-subjects-edit',
                props: { lead }
              })
            "
          >
            <v-icon>edit</v-icon>
          </v-btn>
          <v-chip-subject
            :subject="subject"
            v-for="subject in lead.subjects"
            :key="subject.id"
          />
        </v-sheet>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script type="text/babel">
import VChipSubject from "@/components/subject/VChipSubject";
import LeadMixin from "@/components/submission/lead/LeadMixin";

export default {
  name: "submission-lead-subjects",
  mixins: [LeadMixin],
  components: { VChipSubject }
};
</script>
