var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{staticClass:"pos-relative",attrs:{"xs12":"","py-1":""}},[_c('span',{class:{
        'font-weight-bold': true,
        'grey--text': _vm.hasValue,
        'warning white--text px-2': !_vm.hasValue
      }},[_vm._v(" "+_vm._s(_vm.submissionField.label)+" ")]),(_vm.hover)?_c('v-btn',{attrs:{"icon":"","absolute":"","right":""},on:{"click":function($event){return _vm.$emit('open-dialog', {
          type: 'submission-field-edit-dialog',
          props: { submissionField: _vm.submissionField }
        })}}},[_c('v-icon',[_vm._v("far fa-edit")])],1):_vm._e()],1),_c('v-flex',{attrs:{"xs12":"","py-1":""}},[_c(("submission-field-" + (_vm.hasOptions ? 'options' : 'text')),{tag:"component",attrs:{"submission-field":_vm.submissionField}})],1),(!_vm.hasValue)?_c('v-flex',{staticClass:"warning--text",attrs:{"xs12":"","py-1":""}},[_vm._v(" This field is required. ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }