<script type="text/babel">
import { findIndex } from "lodash";
import SubmissionLeadOffersCreateCustom from "@/components/submission/lead/CreateCustomOffer";
import LeadMixin from "@/components/submission/lead/LeadMixin";

export default {
  name: "submission-lead-offers-create",
  mixins: [LeadMixin],
  components: {
    SubmissionLeadOffersCreateCustom
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    autoclaiming: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    creatingOffers: []
  }),
  methods: {
    async createOffer(column) {
      this.creatingOffers.push({
        filter: column.filter.id,
        subject: column.subject.id
      });
      await this.$store.dispatch("submission/createOffer", {
        leadId: this.lead.id,
        filterId: column.filter.id,
        subjectId: column.subject.id,
        price: column.price
      });
      const index = findIndex(
        this.creatingOffers,
        item =>
          item.filter === column.filter.id && item.subject === column.subject.id
      );
      this.creatingOffers.splice(index, 1);
    }
  }
};
</script>

<template>
  <v-container fluid pa-1>
    <template v-if="!isLeadSaturated">
      <v-layout row>
        <v-flex xs12>
          <v-subheader class="submission-details__subheader">
            create a new offer
            <v-spacer />
            <v-btn icon @click="$emit('input', false)">
              <v-icon small>far fa-times</v-icon>
            </v-btn>
          </v-subheader>
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex xs12>
          <submission-lead-offers-create-custom
            :lead-id="lead.id"
            :offers="lead.offers"
            :creating-offers="creatingOffers"
            @create="createOffer"
          />
        </v-flex>
      </v-layout>
    </template>
  </v-container>
</template>
