<template>
  <v-menu v-if="actions.length > 0" bottom left class="pl-2">
    <v-btn slot="activator" dark icon>
      <v-icon>far fa-ellipsis-v</v-icon>
    </v-btn>
    <v-list light>
      <template v-for="(action, i) in actions">
        <v-divider v-if="action.type === 'divider'" :key="`${i}-divider`" />
        <v-list-tile
          v-else-if="action.type === 'checkbox'"
          :key="`${i}-checkbox`"
          @click.prevent="$emit(`update:${action.emitName}`, !action.prop)"
        >
          <v-list-tile-action>
            <v-checkbox :value="action.prop" readonly></v-checkbox>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title>{{ action.text }}</v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
        <v-list-tile
          v-else
          :disabled="action.disabled"
          @click="action.click"
          :key="`${i}`"
        >
          <v-list-tile-title>{{ action.text }}</v-list-tile-title>
        </v-list-tile>
      </template>
    </v-list>
  </v-menu>
</template>
<script type="text/babel">
import LeadMixin from "./LeadMixin";

export default {
  name: "submission-lead-header-actions",
  mixins: [LeadMixin],
  props: {
    showLeadFields: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    actions() {
      return [
        {
          text: this.isAutoclaimed ? "Force re-autoclaim" : "Force autoclaim",
          disabled: this.hasOffers,
          click: () =>
            this.$emit("open-dialog", {
              type: "submission-dialog-lead-autoclaim",
              props: { lead: this.lead, force: true }
            })
        },
        {
          type: "divider"
        },
        {
          type: "checkbox",
          prop: this.showLeadFields,
          emitName: "show-lead-fields",
          text: "Force show lead fields"
        }
      ];
    }
  }
};
</script>
