<script type="text/babel">
import SubmissionLead from "@/components/submission/lead/Details";

export default {
  name: "submission-leads",
  components: { SubmissionLead },
  props: {
    leads: {
      type: Array,
      required: true
    },
    creatable: {
      type: Boolean,
      default: false
    },
    creating: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<template>
  <div>
    <submission-lead
      v-for="lead in leads"
      :key="lead.id"
      :lead="lead"
      :last="leads.length === 1"
      @open-dialog="$emit('open-dialog', arguments[0])"
      @autoclaim="$store.dispatch('submission/autoclaimLead', arguments[0])"
    />
    <div class="text-xs-center">
      <v-btn
        v-if="creatable"
        color="primary"
        flat
        :disabled="creating"
        :loading="creating"
        @click="$emit('create')"
      >
        <v-icon class="pr-3">far fa-plus</v-icon>
        CREATE ADDITIONAL LEAD
      </v-btn>
    </div>
  </div>
</template>
