<script type="text/babel">
import BAlert from "@/components/generic/BAlert";

export default {
  name: "submission-state-validation",
  components: { BAlert },
  props: {
    store: {
      type: String,
      required: true
    },
    validation: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    resolving: false
  }),
  computed: {
    canProcessValidations() {
      return this.$store.getters[`${this.store}/canProcessValidations`];
    }
  },
  methods: {
    async resolveValidation(isIgnore = true) {
      this.resolving = true;
      try {
        const action = isIgnore ? "ignoreValidation" : "unignoreValidation";
        await this.$store.dispatch(`${this.store}/${action}`, {
          id: this.validation.validationRule.id
        });
      } finally {
        this.resolving = false;
      }
    }
  }
};
</script>

<template>
  <b-alert
    :type="validation.isIgnored ? 'INFO' : 'WARNING'"
    icon
    header="Validation failed"
    :message="validation.validationRule.name"
  >
    <template v-slot:actions>
      <v-btn
        :disabled="!canProcessValidations || resolving"
        color="error"
        small
        v-if="validation.validationRule.isRejectable && !validation.isIgnored"
        @click="
          $store.commit(`${store}/openDialog`, {
            type: 'submission-dialog-reject',
            props: {
              rejectReason: validation.validationRule.rejectReason
            }
          })
        "
        >reject
      </v-btn>
      <v-btn
        :disabled="!canProcessValidations || resolving"
        :loading="resolving"
        color="warning"
        small
        v-if="validation.validationRule.isIgnorable && !validation.isIgnored"
        @click="resolveValidation(true)"
      >
        ignore
      </v-btn>
      <v-btn
        :disabled="!canProcessValidations || resolving"
        :loading="resolving"
        color="warning"
        small
        v-if="validation.isIgnored"
        @click="resolveValidation(false)"
        >unignore
      </v-btn>
    </template>
  </b-alert>
</template>
