<script type="text/babel">
import { cloneDeep } from "lodash";
import { isChanged } from "@/store/change";
import IsValidationRoot from "@/components/mixins/validation/IsValidationRoot";
import CanValidate from "@/components/mixins/validation/CanValidate";

export default {
  name: "dialog-mixin",
  mixins: [CanValidate, IsValidationRoot],
  data: () => ({
    form: {},
    loading: false
  }),
  created() {
    this.form = cloneDeep(this.unchangedForm);
  },
  computed: {
    unchangedForm() {
      return {};
    },
    isChanged() {
      return isChanged(this.form, this.unchangedForm);
    }
  },
  methods: {
    confirm(options = {}) {
      this.validate(() => this.callSave(options));
    },
    async callSave({ setLoading = true, ...options }) {
      if (setLoading !== false) {
        this.loading = true;
      }
      try {
        await this.save(options);
      } finally {
        if (setLoading !== false) {
          this.loading = false;
        }
      }
    }
  }
};
</script>
