<script type="text/babel">
import OfferFieldMixin from "@/components/submission/lead/offer/fields/OfferFieldMixin";

export default {
  name: "submission-lead-offer-field-options",
  mixins: [OfferFieldMixin],
  props: {
    onlySelected: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    options() {
      return this.onlySelected
        ? this.offerField.options.filter(option => this.isSelected(option))
        : this.offerField.options;
    }
  }
};
</script>

<template>
  <ul class="fa-ul ml-4">
    <li
      v-for="option in options"
      :key="option.id"
      :class="{
        'grey--text text--lighten-1': !isSelected(option)
      }"
    >
      <span class="fa-li">
        <i
          :class="{
            'fas fa-check-square': isSelected(option),
            'far fa-square': !isSelected(option)
          }"
        />
      </span>
      {{ option.label }}
    </li>
  </ul>
</template>
