<script type="text/babel">
import SubmissionGeocodeListItem from "@/components/submission/details/geocode/Item";

export default {
  name: "submission-geocode-list",
  components: { SubmissionGeocodeListItem }
};
</script>

<template>
  <v-list dense class="orange lighten-4">
    <template v-for="(item, index) in $store.state.submission.geocodeOptions">
      <v-divider v-if="index > 0" :key="`${index}-divider`" />
      <submission-geocode-list-item
        :item="item"
        :key="index"
        @geocode="$emit('geocode', item)"
      />
    </template>
  </v-list>
</template>
