<script type="text/babel">
import VAutocompleteSubject from "@/components/subject/VAutocompleteSubject";
import SubmissionMixin from "@/components/submission/details/SubmissionMixin";
import SubmissionDialog from "@/components/submission/dialog/SubmissionDialog";
import DialogMixin from "@/components/submission/dialog/DialogMixin";

export default {
  name: "submission-dialog-subject-edit",
  components: { SubmissionDialog, VAutocompleteSubject },
  mixins: [DialogMixin, SubmissionMixin],
  computed: {
    unchangedForm() {
      return {
        subjects: this.submission.subjects.map(subject => subject.id)
      };
    }
  },
  methods: {
    async save() {
      await this.$store.dispatch("submission/updateSubjects", this.form);
    }
  }
};
</script>

<template>
  <submission-dialog
    dialog-type="submission-dialog-subject-edit"
    header="change submission subjects"
    color="warning"
    confirmText="change"
    :changed="true"
    :loading="loading"
    @confirm="confirm"
  >
    <v-sheet class="pa-3">
      <v-layout row>
        <v-flex xs3></v-flex>
        <v-flex xs6 class="subheading text-xs-center">
          <p>
            Any existing leads will be removed, as changing subjects can
            potentially change the submission's questions as well.
          </p>
        </v-flex>
        <v-flex xs3></v-flex>
      </v-layout>
      <v-layout row>
        <v-flex xs6 class="pl-3 pr-4">
          <v-form>
            <v-autocomplete-subject v-model="form.subjects" multiple job />
          </v-form>
        </v-flex>
        <v-flex xs6 class="pl-4 pr-3">
          <v-autocomplete-subject
            :value="submission.originalSubjects"
            label="Original subjects"
            multiple
            job
            disabled
            readonly
          />
        </v-flex>
      </v-layout>
    </v-sheet>
  </submission-dialog>
</template>
