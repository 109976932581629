<script type="text/babel">
export default {
  name: "loading-tile"
};
</script>

<template>
  <v-list-tile>
    <v-list-tile-content>
      <div class="full-width text-xs-center">
        <v-progress-circular indeterminate color="primary" />
      </div>
    </v-list-tile-content>
  </v-list-tile>
</template>

<style scoped>
.text--cursor-pointer {
  cursor: pointer;
}
</style>
