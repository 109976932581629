<script type="text/babel">
import { isNil } from "lodash";
import NowMixin from "@/components/mixins/NowMixin";
import BAlert from "@/components/generic/BAlert";

export default {
  name: "submission-state-unprocessed",
  components: { BAlert },
  inject: {
    $validator: "$validator"
  },
  mixins: [NowMixin],
  props: {
    store: {
      type: String,
      required: true
    }
  },
  data: () => ({
    processing: false,
    error: null
  }),
  computed: {
    hasError() {
      return !isNil(this.error);
    },
    state() {
      return this.$store.getters[`${this.store}/state`];
    },
    previousStateAt() {
      return this.$store.getters[`${this.store}/previousStateAt`];
    },
    isLastUpdateTooLongAgo() {
      return this.getDiffSinceNow(this.previousStateAt) < -60;
    },
    isDataStale() {
      return this.getDiffSinceNow(this.nowCreated) < -10;
    }
  },
  methods: {
    async process() {
      try {
        this.processing = true;
        this.error = null;
        await this.$store.dispatch("submission/process");
      } catch ({ response }) {
        const error = response.data.message;
        this.error = error;
      } finally {
        this.processing = false;
      }
    }
  }
};
</script>

<template>
  <b-alert type="warning" icon important>
    <div class="headline text-uppercase warning--text text--darken-2">
      This submission hasn't finished processing yet.
    </div>
    <div>
      It's current state is
      <span class="warning--text text--darken-2 text-uppercase">{{
        state.replace("-", " ")
      }}</span>
      and needs further processing before it can be interacted with.
    </div>
    <div class="subheading">
      Last status update:
      {{ previousStateAt | moment("from", "now") }}.
    </div>
    <div class="subheading font-weight-bold" v-if="isLastUpdateTooLongAgo">
      It should reasonably have finished processing by now. You can try to force
      it to continue processing. If that doesn't work, report it to IT.
    </div>
    <div v-else>
      Please have a little goddamn patience.
    </div>
    <template v-slot:actions>
      <v-tooltip :disabled="!isDataStale" top>
        <template v-slot:activator="{ on }">
          <div class="d-inline-block" v-on="on">
            <v-btn
              color="error"
              small
              :loading="processing"
              :disabled="processing || isDataStale"
              @click="process"
            >
              <v-icon v-if="hasError">
                far fa-exclamation-triangle fa-fw
              </v-icon>
              FORCE CONTINUE PROCESSING
            </v-btn>
          </div>
        </template>
        <span
          >Please refresh the page to make sure your view is up to date.</span
        >
      </v-tooltip>
      <div v-if="hasError">Processing failed: {{ error }}</div>
    </template>
  </b-alert>
</template>
