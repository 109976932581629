// This methods needs some serious documentation and variable naming before we can try and reduce it.
export function diffString(o, n) {
  o = o.replace(/\s+$/, "");
  n = n.replace(/\s+$/, "");

  let out = diff(o == "" ? [] : o.split(/\s+/), n == "" ? [] : n.split(/\s+/));

  let newlines = o.split(/\s+\n/);

  let str = "";

  if (out.n.length == 0) {
    for (let i = 0; i < out.o.length; i++) {
      str += "<del>" + out.o[i] + "</del>";
    }
  } else {
    if (out.n[0].text == null) {
      for (n = 0; n < out.o.length && out.o[n].text == null; n++) {
        str += " " + "<del>" + out.o[n] + "</del>" + " ";
      }
    }

    for (let i = 0; i < out.n.length; i++) {
      if (out.n[i].text == null) {
        str += "<ins> " + out.n[i] + "</ins>";
      } else {
        let pre = "";

        for (
          n = out.n[i].row + 1;
          n < out.o.length && out.o[n].text == null;
          n++
        ) {
          pre += " " + "<del>" + out.o[n] + "</del>" + " ";
        }
        str += " " + out.n[i].text + pre;
      }
    }
  }

  newlines.forEach(e => {
    str = str.replace(e, e + "<br>");
  });

  return str;
}

function diff(o, n) {
  let ns = {};
  let os = {};

  for (let i = 0; i < n.length; i++) {
    if (ns[n[i]] == null) ns[n[i]] = { rows: [], o: null };
    ns[n[i]].rows.push(i);
  }

  for (let i = 0; i < o.length; i++) {
    if (os[o[i]] == null) os[o[i]] = { rows: [], n: null };
    os[o[i]].rows.push(i);
  }

  for (let i in ns) {
    if (
      ns[i].rows.length == 1 &&
      typeof os[i] != "undefined" &&
      os[i].rows.length == 1
    ) {
      n[ns[i].rows[0]] = { text: n[ns[i].rows[0]], row: os[i].rows[0] };
      o[os[i].rows[0]] = { text: o[os[i].rows[0]], row: ns[i].rows[0] };
    }
  }

  for (let i = 0; i < n.length - 1; i++) {
    if (
      n[i].text != null &&
      n[i + 1].text == null &&
      n[i].row + 1 < o.length &&
      o[n[i].row + 1].text == null &&
      n[i + 1] == o[n[i].row + 1]
    ) {
      n[i + 1] = { text: n[i + 1], row: n[i].row + 1 };
      o[n[i].row + 1] = { text: o[n[i].row + 1], row: i + 1 };
    }
  }

  for (let i = n.length - 1; i > 0; i--) {
    if (
      n[i].text != null &&
      n[i - 1].text == null &&
      n[i].row > 0 &&
      o[n[i].row - 1].text == null &&
      n[i - 1] == o[n[i].row - 1]
    ) {
      n[i - 1] = { text: n[i - 1], row: n[i].row - 1 };
      o[n[i].row - 1] = { text: o[n[i].row - 1], row: i - 1 };
    }
  }

  return { o: o, n: n };
}
