<script type="text/babel">
import SubmissionFieldMixin from "./SubmissionFieldMixin";
import SubmissionDialog from "@/components/submission/dialog/SubmissionDialog";
import DialogMixin from "@/components/submission/dialog/DialogMixin";

export default {
  name: "submission-field-edit-dialog",
  components: { SubmissionDialog },
  mixins: [DialogMixin, SubmissionFieldMixin],
  computed: {
    unchangedForm() {
      const form = {
        id: this.submissionField.id,
        value: null
      };
      if (this.hasOptions) {
        const selectedOptionIds = this.selectedOptions.map(option => option.id);
        form.value = this.isMultiple
          ? selectedOptionIds
          : selectedOptionIds[0] || null;
      } else {
        form.value = this.submissionField.value;
      }
      return form;
    }
  },
  methods: {
    isSelected(option) {
      if (this.isMultiple) {
        return this.form.value.includes(option.id);
      }
      return this.form.value === option.id;
    },
    getOptionColor(option) {
      return this.isSelected(option) === option.isOriginallySelected
        ? "primary"
        : "accent";
    },
    getOptionBackgroundColor(option) {
      return !this.isSelected(option) && option.isOriginallySelected
        ? "accent"
        : undefined;
    },
    async save() {
      await this.$store.dispatch("submission/updateSubmissionField", this.form);
    }
  }
};
</script>

<template>
  <submission-dialog
    dialog-type="submission-field-edit-dialog"
    header="edit submission field"
    :loading="loading"
    :changed="isChanged"
    @confirm="confirm"
  >
    <template v-if="hasOptions">
      <template v-if="isMultiple">
        <v-checkbox
          v-for="option in submissionField.options"
          :key="option.id"
          v-model="form.value"
          :label="option.label"
          :color="getOptionColor(option)"
          :background-color="getOptionBackgroundColor(option)"
          :value="option.id"
          hide-details
        ></v-checkbox>
      </template>
      <v-radio-group v-else v-model="form.value" column>
        <v-radio
          v-for="option in submissionField.options"
          :key="option.id"
          :label="option.label"
          :color="getOptionColor(option)"
          :value="option.id"
        ></v-radio>
      </v-radio-group>
    </template>
    <v-textarea
      v-else-if="submissionField.type === 'TEXTAREA'"
      v-model="form.value"
      v-validate="'required|max:3000'"
      data-vv-name="value"
      auto-grow
      :error-messages="errors.collect('value')"
      :counter="3000"
      :label="submissionField.label"
    />
    <v-text-field
      v-else
      v-model="form.value"
      v-validate="'required|max:255'"
      data-vv-name="value"
      :error-messages="errors.collect('value')"
      :counter="250"
      :label="submissionField.label"
    />
  </submission-dialog>
</template>
