<script type="text/babel">
import SubmissionContactInformation from "@/components/submission/details/ContactInformation";
import SubmissionMetaInformation from "@/components/submission/details/MetaInformation";
import SubmissionLocation from "@/components/submission/details/Location";
import SubmissionFields from "@/components/submission/details/Fields";
import SubmissionHeader from "@/components/submission/details/Header";

import SubmissionMap from "./details/Map";
import SubmissionGeocode from "@/components/submission/details/Geocode";
import SubmissionMixin from "@/components/submission/details/SubmissionMixin";
import SubmissionDetailSimilarSubmissions from "@/components/submission/details/SimilarSubmissions";
import SubmissionState from "@/components/submission/details/SubmissionState";

export default {
  name: "submission-details",
  inject: {
    $validator: "$validator"
  },
  mixins: [SubmissionMixin],
  props: {
    store: {
      type: String,
      required: true
    },
    processing: {
      type: Boolean,
      default: false
    }
  },
  components: {
    SubmissionState,
    SubmissionDetailSimilarSubmissions,
    SubmissionGeocode,
    SubmissionMap,
    SubmissionHeader,
    SubmissionFields,
    SubmissionLocation,
    SubmissionMetaInformation,
    SubmissionContactInformation
  },
  data: () => ({
    isOpen: true
  })
};
</script>

<template>
  <v-card class="mb-3">
    <submission-header
      :store="store"
      :submission="submission"
      :open="isOpen"
      @toggle="isOpen = !isOpen"
      @open-dialog="$emit('open-dialog', arguments[0])"
    />
    <template v-if="isOpen">
      <submission-state
        :store="store"
        @open-dialog="$emit('open-dialog', arguments[0])"
      />
      <div class="d-flex pb-2 px-2">
        <div class="flex-1 white">
          <submission-contact-information
            :store="store"
            :submission="submission"
            @open-dialog="$emit('open-dialog', arguments[0])"
          />
        </div>
        <div class="flex-1 blue lighten-5">
          <submission-meta-information
            :store="store"
            :submission="submission"
            @open-dialog="$emit('open-dialog', arguments[0])"
          />
        </div>
        <div
          :class="{
            'flex-2': true,
            'd-flex': isGeocoded,
            secondary: isGeocoded,
            'orange lighten-4': !isGeocoded
          }"
        >
          <template v-if="isGeocoded">
            <div class="flex-1">
              <submission-location
                :store="store"
                :submission="submission"
                @open-dialog="$emit('open-dialog', arguments[0])"
              />
            </div>
            <div class="flex-1 pa-2">
              <submission-map :store="store" :submission="submission" />
            </div>
          </template>
          <submission-geocode
            v-else
            :store="store"
            :submission="submission"
            @fetch-geocode="$emit('fetch-geocode', arguments[0])"
            @geocode="$emit('geocode', arguments[0])"
          />
        </div>
      </div>
      <submission-fields
        :fields="submission.fields"
        @open-dialog="$emit('open-dialog', arguments[0])"
      />
      <submission-detail-similar-submissions
        :submission-id="submission.id"
        :submission-email="submission.emailAddress"
        :submission-telephone-numbers="submission.telephoneNumbers"
        :similars="submission.similars"
      />
    </template>
  </v-card>
</template>
