<script type="text/babel">
import SubmissionMixin from "@/components/submission/details/SubmissionMixin";

export default {
  name: "submission-geocode-address",
  mixins: [SubmissionMixin],
  props: {
    store: {
      type: String,
      required: true
    }
  }
};
</script>

<template>
  <v-container fluid>
    <v-layout row wrap>
      <v-flex xs6 lg5 py-0 px-1>
        <v-text-field :value="street" label="Street" disabled />
      </v-flex>
      <v-flex xs3 lg1 py-0 px-1>
        <v-text-field :value="streetNumber" label="Number" disabled />
      </v-flex>
      <v-flex xs3 lg1 py-0 px-1>
        <v-text-field :value="box" label="Box" disabled />
      </v-flex>
      <v-flex xs4 lg2 py-0 px-1>
        <v-text-field :value="postalCode" label="Postal Code" disabled />
      </v-flex>
      <v-flex xs8 lg3 py-0 px-1>
        <v-text-field :value="locality" label="City" disabled />
      </v-flex>
    </v-layout>
  </v-container>
</template>
