<script type="text/babel">
export default {
  name: "empty-tile"
};
</script>

<template>
  <v-list-tile>
    <v-list-tile-content> </v-list-tile-content>
  </v-list-tile>
</template>
