<script type="text/babel">
import SubmissionLeadSubjectsEdit from "./lead/subject/Edit";
import SubmissionFieldEditDialog from "@/components/submission/details/fields/EditDialog";
import SubmissionLeadOfferFieldEdit from "./lead/offer/fields/OfferFieldEdit";
import SubmissionContactInformationEdit from "@/components/submission/details/SubmissionDetailsContactEdit";
import SubmissionDialogAddressReset from "@/components/submission/dialog/AddressReset";
import SubmissionDialogLeadAutoclaim from "./dialog/Autoclaim";
import SubmissionLeadFieldEdit from "@/components/submission/lead/fields/Edit";
import SubmissionDialogSubjectEdit from "@/components/submission/dialog/SubjectEdit";
import SubmissionDialogAddressEdit from "@/components/submission/dialog/SubmissionAddressEdit";
import SubmissionDialogReject from "@/components/submission/dialog/Reject";
import SubmissionDialogMarkAsSpam from "@/components/submission/dialog/MarkAsSpam";
import SubmissionDialogRevalidate from "@/components/submission/dialog/Revalidate";

export default {
  name: "submission-dialogs",
  props: {
    store: {
      type: String,
      required: true
    }
  },
  components: {
    SubmissionDialogAddressEdit,
    SubmissionDialogSubjectEdit,
    SubmissionLeadFieldEdit,
    SubmissionDialogLeadAutoclaim,
    SubmissionDialogAddressReset,
    SubmissionContactInformationEdit,
    SubmissionLeadOfferFieldEdit,
    SubmissionFieldEditDialog,
    SubmissionLeadSubjectsEdit,
    SubmissionDialogRevalidate,
    SubmissionDialogMarkAsSpam,
    SubmissionDialogReject
  },
  computed: {
    internalDialog() {
      return !!this.$store.state[this.store].dialog.type;
    }
  }
};
</script>

<template>
  <component
    :is="$store.state[this.store].dialog.type"
    v-bind="$store.state[this.store].dialog.props"
  />
</template>
