<script type="text/babel">
import SubmissionDetailSimilarSubmissionsRow from "@/components/submission/details/SimilarSubmissionsRow";

export default {
  name: "submission-detail-similar-submissions",
  props: {
    submissionId: {
      type: Number,
      required: true
    },
    submissionEmail: {
      type: String,
      required: true
    },
    submissionTelephoneNumbers: {
      type: Array,
      required: true
    },
    similars: {
      type: Array,
      required: false
    }
  },
  components: {
    SubmissionDetailSimilarSubmissionsRow
  },
  data: () => ({
    submissions: [],
    pagination: {
      page: 1,
      rowsPerPage: 5,
      sortBy: undefined,
      descending: false,
      totalItems: 0
    },
    isLoading: false,
    dialog: false,
    headers: [
      { text: "Id", value: "id", sortable: false },
      { text: "Name", value: "full_name", sortable: false },
      { text: "Email", value: "email", sortable: false },
      { text: "Phone", value: "telephone_numbers", sortable: false },
      { text: "Subjects", value: "subject", sortable: false },
      { text: "Leads", value: "leads", sortable: false },
      { text: "Completed at", value: "completed_at", sortable: false },
      { text: "Status", value: "id", sortable: false },
      {
        text: "Actions",
        align: "right",
        value: "name",
        sortable: false,
        width: "100px"
      }
    ]
  }),
  computed: {
    hasSubmissions() {
      return this.similars.length > 0;
    },
    hasSubmissionsOtherThanCurrent() {
      return (
        this.hasSubmissions &&
        this.similars.filter(submission => submission.id !== this.submissionId)
          .length > 0
      );
    }
  }
};
</script>

<template>
  <v-container fluid pb-0>
    <v-layout row wrap>
      <v-flex xs12 pt-0 px-0>
        <div v-if="isLoading && !hasSubmissions" class="text-xs-center">
          <span class="pr-3 headline">Loading similar submissions...</span>
          <v-progress-circular color="primary" indeterminate />
        </div>
        <div v-else-if="!hasSubmissionsOtherThanCurrent" class="text-xs-center">
          <span class="headline grey--text">No similar submissions found.</span>
        </div>
        <v-data-table
          v-else
          :headers="headers"
          :items="similars"
          :pagination.sync="pagination"
          :total-items="pagination.totalItems"
          :rows-per-page-items="[5, 10, 20]"
          :loading="isLoading"
          class="elevation-1"
        >
          <submission-detail-similar-submissions-row
            slot="items"
            slot-scope="{ item }"
            :submission="item"
            :current="item.id === submissionId"
            :current-email="submissionEmail"
            :current-telephone-numbers="submissionTelephoneNumbers"
          />
        </v-data-table>
      </v-flex>
    </v-layout>
  </v-container>
</template>
