<script>
import copy from "copy-to-clipboard";
import { isNil } from "lodash";

export default {
  name: "copy-to-clipboard-mixin",
  methods: {
    copyToClipboard(text, message = null) {
      copy(text);
      this.$store.dispatch("snackbar/addInfo", {
        text: isNil(message) ? `Sent "${text}" to clipboard.` : message
      });
    }
  }
};
</script>
