<script type="text/babel">
import { isNil } from "lodash";
import VAutocompleteRejectReason from "../../reject-reason/VAutocompleteRejectReason";
import SubmissionDialog from "@/components/submission/dialog/SubmissionDialog";
import DialogMixin from "@/components/submission/dialog/DialogMixin";

export default {
  name: "submission-dialog-reject",
  components: { SubmissionDialog, VAutocompleteRejectReason },
  mixins: [DialogMixin],
  props: {
    rejectReason: {
      type: Object,
      default: null
    }
  },
  data: () => ({
    softLoading: false
  }),
  created() {
    if (!isNil(this.rejectReason)) {
      this.form.rejectReasonId = this.rejectReason.id;
    }
  },
  computed: {
    unchangedForm() {
      return {
        rejectReasonId: null,
        rejectComment: null,
        customEmailText: null
      };
    },
    selectedRejectReason() {
      return this.$store.state["reject-reasons-select"]
        ? this.$store.state["reject-reasons-select"].entities.entity[
            this.form.rejectReasonId
          ]
        : null;
    },
    softRejectable() {
      return this.selectedRejectReason
        ? this.selectedRejectReason.isSoftRejectable
        : false;
    },
    softRejectCustomizable() {
      return this.selectedRejectReason
        ? this.selectedRejectReason.isSoftRejectCustomizable
        : false;
    }
  },
  methods: {
    async save({ softReject = false }) {
      // Clone the form and use it as payload.
      const payload = { ...this.form };

      // If softRejected, add isSoft = true to the payload. + loading workaround >_>
      if (softReject) {
        payload.isSoft = true;
        this.softLoading = true;
      } else {
        this.loading = true;
      }

      try {
        await this.$store.dispatch("submission/reject", payload);
      } finally {
        this.softLoading = false;
        this.loading = false;
      }
    }
  }
};
</script>

<template>
  <submission-dialog
    dialog-type="submission-dialog-reject"
    header="reject submission"
    color="error"
    confirmText="Reject"
    :changed="isChanged"
    :disabled-buttons="softLoading"
    :loading="loading"
    @confirm="confirm"
  >
    <v-autocomplete-reject-reason
      v-model="form.rejectReasonId"
      name="rejectReasonId"
      required
    />
    <v-textarea
      v-model="form.rejectComment"
      v-validate="'max:500'"
      data-vv-name="rejectComment"
      :error-messages="errors.collect('rejectComment')"
      label="Extra comment (optional)"
      :counter="500"
    />
    <v-textarea
      v-if="softRejectCustomizable"
      v-model="form.customEmailText"
      v-validate="'max:500'"
      data-vv-name="customEmailText"
      :error-messages="errors.collect('customEmailText')"
      label="Custom email text override"
      :counter="500"
    />
    <v-btn
      slot="prepend-confirm"
      v-if="softRejectable"
      color="warning"
      small
      :disabled="loading || softLoading"
      :loading="softLoading"
      @click="confirm({ setLoading: false, softReject: true })"
      >Soft Reject
    </v-btn>
  </submission-dialog>
</template>
