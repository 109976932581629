<template>
  <v-chip
    small
    label
    :color="contextColor"
    :dark="contextDark"
    class="text-uppercase"
  >
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <span v-on="on">
          <i class="far fa-building fa-fw" /> {{ offer.company.name }}
          <i class="far fa-dot-circle fa-fw ml-1" /> {{ offer.subject.name }}
        </span>
      </template>
      <span class="text-uppercase" v-if="isRejected || isSoftRejected"
        >{{ state }} : {{ this.offer.rejectionComment }}</span
      >
      <span class="text-uppercase" v-else>{{ state }}</span>
    </v-tooltip>
  </v-chip>
</template>
<script type="text/babel">
import OfferMixin from "@/components/submission/lead/offer/OfferMixin";

export default {
  name: "offer-state-chip",
  mixins: [OfferMixin]
};
</script>
